import { useMemo, useEffect } from 'react';

import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

import { isEmpty } from '../checks';
import { isNotNil } from '../guards';
import type { Nullable } from '../object';

import useSelfUpdatingRef from './useSelfUpdatingRef';
import useUnmount from './useUnmount';

export interface BaseDrawer {
  readonly __typename?: string;
  readonly child?: BaseDrawer | null;
  readonly id: string;
  readonly visible: boolean;
}

export const flattenDrawersTree = <T extends BaseDrawer>(
  drawersTree: T | null | undefined,
): (T | null | undefined)[] => {
  const child = drawersTree?.child as T;

  return [drawersTree, ...isNil(child)
    ? []
    : flattenDrawersTree(child),
  ];
};

export type AvailableDrawer<Typenames extends string> = Typenames
| { drawer: Typenames; children: AvailableDrawer<Typenames>[] };

export const matchesStructuraly = <Typenames extends string>(
  structures: AvailableDrawer<Typenames>[],
) => <U extends BaseDrawer>(drawers: U[]): U[] => {
  const recurse = (structure: AvailableDrawer<Typenames>, remainingDrawers: U[]): boolean => {
    if (isEmpty(remainingDrawers)) {
      return false;
    }

    const [current, ...others] = remainingDrawers;
    const structureTypename = isString(structure) ? structure : structure.drawer;

    if (isNil(structureTypename)) {
      return false;
    }

    if (structureTypename !== current.__typename) {
      return false;
    }

    if (isString(structure)) {
      return isEmpty(others);
    }

    if (isEmpty(others)) {
      return true;
    }

    return structure.children.some((child) => recurse(child, others));
  };

  return isNil(structures.find((structure) => recurse(structure, drawers)))
    ? []
    : drawers;
};

type UseDrawersArgument<T extends BaseDrawer> = Readonly<{
  onClose?: (arg: T) => void;
  closeAll: () => void;
  currentRootDrawer: Nullable<T>;
  previousRootDrawer: Nullable<T>;
  onNewDrawers?: (drawers: T[]) => void;
  drawerFilter?: (arg: T[]) => T[];
}>;

type UseDrawersReturnValue<T extends BaseDrawer> = Readonly<{
  drawers: T[];
}>;

const useDrawers = <T extends BaseDrawer>({
  onClose,
  currentRootDrawer,
  previousRootDrawer,
  closeAll,
  onNewDrawers,
  drawerFilter,
}: UseDrawersArgument<T>): UseDrawersReturnValue<T> => {
  const onCloseRef = useSelfUpdatingRef(onClose);
  const onNewDrawersRef = useSelfUpdatingRef(onNewDrawers);
  const drawerFilterRef = useSelfUpdatingRef(drawerFilter ?? ((arg: T[]) => arg));

  const drawers = useMemo(
    () => drawerFilterRef.current(flattenDrawersTree(currentRootDrawer)
      .filter(isNotNil)),
    [currentRootDrawer, drawerFilterRef],
  );

  const previousDrawers = useMemo(
    () => drawerFilterRef.current(flattenDrawersTree(previousRootDrawer)
      .filter(isNotNil)),
    [previousRootDrawer, drawerFilterRef],
  );

  useEffect(() => {
    previousDrawers.forEach((drawer) => {
      const wasVisible = drawer.visible;
      const newDrawerState = drawers.find((d) => d.id === drawer.id);

      const isStillOpen = newDrawerState?.visible === true;

      if (wasVisible && !isStillOpen) {
        onCloseRef.current?.(newDrawerState ?? drawer);
      }
    });
  }, [previousDrawers, drawers, onCloseRef]);

  useEffect(() => {
    const visibleDrawers = drawers.filter((drawer) => drawer.visible);

    const hasUpdates = visibleDrawers.length !== previousDrawers.length
      || previousDrawers.some((drawer, index) => visibleDrawers[index].id !== drawer.id);

    if (hasUpdates) {
      onNewDrawersRef.current?.(visibleDrawers);
    }
  }, [drawers, onNewDrawersRef, previousDrawers]);

  useUnmount(closeAll);

  return { drawers };
};

export default useDrawers;
