import type { FeatureFlags } from '@virtuslab/nfs-shared/src/config/featureFlags';

export const featureFlags: FeatureFlags = {
  allowReportingBreak: true,
  allowMaternityLeave: true,
  allowChangingInvoiceStatus: true,
  ignoreWorkTagLimits: true,
  useACL: true,
  enforceInvoiceValidation: false,
  clearCachePeriodically: false,
  ignoreLeaveLimits: true,
};

export default {};
