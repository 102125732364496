import type { ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';

import defer from 'lodash/defer';
import omit from 'lodash/fp/omit';
import isNil from 'lodash/isNil';
import { useMount } from 'react-use';

import type { AbstractDrawer, AbstractDrawerUnionInterface } from '@virtuslab/nfs-shared/src/schema/admin';
import { useRootDrawerQuery } from '@virtuslab/nfs-shared/src/schema/admin';
import { isEmpty } from '@virtuslab/nfs-shared/src/services/checks';
import { isArrayOf, isObjectWithKeys, isNotNil } from '@virtuslab/nfs-shared/src/services/guards';
import { flattenDrawersTree } from '@virtuslab/nfs-shared/src/services/hooks/useDrawers';
import useURLState from '@virtuslab/nfs-shared/src/services/hooks/useURLState';
import type { Nullable } from '@virtuslab/nfs-shared/src/services/object';

import { everyDrawerModel } from '../../../state/models/Drawers';

import { useMutations } from '../GraphqlProvider';

type Props = Readonly<{
  children: ReactNode;
}>;

const DrawerStateSyncer = ({ children }: Props): ReactElement => {
  const { drawerMutations } = useMutations();

  const {
    data: rootDrawerData,
    previousData: previousRootDrawerData,
  } = useRootDrawerQuery();

  const [urlDrawers, setUrlDrawers] = useURLState<Omit<AbstractDrawerUnionInterface, 'child' | 'id' | 'visible'>[]>({
    key: 'drawers',
    shouldClear: isEmpty,
    assertShape: (data) => isArrayOf(data, (item): item is AbstractDrawer => isObjectWithKeys(item, ['__typename'])),
    navigationOptions: {
      replace: true,
    },
  });

  useMount(() => {
    const createModel = (
      typename: Nullable<keyof typeof everyDrawerModel>,
      args: NonNullable<typeof urlDrawers>[number],
    ): Nullable<AbstractDrawer> => {
      if (isNil(typename) || !(typename in everyDrawerModel)) {
        return null;
      }

      const modelCreator = everyDrawerModel[typename] as ((...args: unknown[]) => AbstractDrawer);
      return modelCreator(args);
    };

    const openSequentially = (drawers: AbstractDrawer[]) => {
      if (isEmpty(drawers)) {
        return;
      }

      const [first, ...others] = drawers;

      defer(() => {
        drawerMutations.openChild(first);
        openSequentially(others);
      });
    };

    if (!isNil(urlDrawers) && !isEmpty(urlDrawers)) {
      const parsedDrawers = urlDrawers
        .map((drawer) => createModel(drawer.__typename, drawer))
        .filter(isNotNil);

      if (parsedDrawers.length !== urlDrawers.length) {
        return;
      }

      const [first, ...others] = parsedDrawers;

      drawerMutations.open(first);
      openSequentially(others);
    }
  });

  useEffect(() => {
    const drawers = flattenDrawersTree(rootDrawerData?.rootDrawer)
      .filter(isNotNil)
      .filter((drawer) => drawer.visible);

    const previousDrawers = flattenDrawersTree(previousRootDrawerData?.rootDrawer)
      .filter(isNotNil)
      .filter((drawer) => drawer.visible);

    const visibleDrawers = drawers.filter((drawer) => drawer.visible);

    const hasUpdates = visibleDrawers.length !== previousDrawers.length
      || previousDrawers.some((drawer, index) => visibleDrawers[index].id !== drawer.id);

    if (hasUpdates) {
      setUrlDrawers(drawers.map((drawer) => omit(['id', 'visible', 'child'], drawer)));
    }
  }, [rootDrawerData, previousRootDrawerData, setUrlDrawers]);

  return (
    <>
      {children}
    </>
  );
};

export default DrawerStateSyncer;
