import { useEffect, useState } from 'react';

import { useAuth } from '@virtuslab/react-oauth2';
import decode from 'jwt-decode';
import isNil from 'lodash/isNil';

import type { Literal } from '../guards';
import type { Nullable } from '../object';

const makeUseProfileInfo = <KeycloakProfileShape extends Literal>(
) => (
  ): Nullable<KeycloakProfileShape> => {
    const auth = useAuth();
    const [decoded, setDecoded] = useState<Nullable<KeycloakProfileShape>>(null);

    useEffect(() => {
      auth.getAccessToken().then((token) => {
        if (isNil(token)) {
          setDecoded(null);
        } else {
          setDecoded(decode(token));
        }
      });
    }, [auth]);

    return decoded;
  };

export default makeUseProfileInfo;
