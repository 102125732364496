
import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { SalariesRoutes } from '../../../config/paths';

const SalariesPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof SalariesRoutes>> = {
  paths: {
    [SalariesRoutes.SALARIES]: {
      render: (props) => <SalariesPage {...props} />,
    },
    [SalariesRoutes.BASE]: {
      render: () => <SalariesPage year={new Date().getFullYear().toString()} />,
    },
  },
};

export default config;
