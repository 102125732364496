import makeChangeLanguage from '@virtuslab/nfs-shared/src/services/i18n/changeLanguage';

import type {
  MessageKey, Messages, EnumMessages,
} from './messages';
import { enumMessage } from './messages';
import i18n, { defaultNS } from './setupI18n';
import type { TranslatingFn } from './translations';

const changeLanguage = makeChangeLanguage(
  i18n,
  defaultNS,
  async (lng) => {
    const pckg: unknown = await import(`./translations/${lng}`);
    return pckg;
  },
);

export {
  i18n,
  changeLanguage,
  enumMessage,
};

export type {
  MessageKey,
  Messages,
  TranslatingFn,
  EnumMessages,
};
