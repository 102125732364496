import not from 'lodash/fp/negate';
import isDate from 'lodash/isDate';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import without from 'lodash/without';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import type { ObjectShape } from 'yup/lib/object';
import type { AnySchema } from 'yup/lib/schema';
import isAbsent from 'yup/lib/util/isAbsent';

import { isEmpty } from '@virtuslab/nfs-shared/src/services/checks';
import type { DateFormat } from '@virtuslab/nfs-shared/src/services/format';

const isAnySchema = (arg: unknown): arg is AnySchema => arg instanceof yup.BaseSchema;

const hasKey = <ObjKey extends string>(
  obj: unknown, key: ObjKey,
): obj is Record<ObjKey, unknown> => Boolean(
    isObject(obj) && key in obj,
  );

yup.addMethod(yup.object, 'allOrNone', function method(this: yup.ObjectSchema<ObjectShape>, fields: string[]) {
  const schema: Record<string, AnySchema> = fields.reduce((newSchema, fieldName) => {
    const field = this.fields[fieldName];

    if (isAnySchema(field)) {
      return {
        ...newSchema,
        [fieldName]: field.test(
          `${fieldName}-required`,
          { key: 'This field must not be empty' },
          (fieldValue, { resolve }) => {
            const otherFields = without(fields, fieldName);
            const otherFieldsValues = otherFields.map((otherField) => resolve(yup.ref(otherField)));

            const partiallyFilledOut = otherFieldsValues.some(not(isEmpty));

            if (partiallyFilledOut) {
              return !isEmpty(fieldValue);
            }

            return true;
          },
        ),
      };
    }

    return { ...newSchema, [fieldName]: field };
  }, {});

  return this.concat(yup.object(schema));
});

yup.addMethod(yup.date, 'inFormat', function method(this: yup.DateSchema, format: DateFormat) {
  return this.test({
    name: 'inFormat',
    // TODO: probably needs to localize the displayed format as well
    message: { key: 'This field must be in a format of {{format}}', values: { format } },
    test: (value, context) => {
      if (isAbsent(value)) {
        return true;
      }

      if (!DateTime.fromJSDate(value).isValid) {
        return false;
      }

      const originalValue = hasKey(context, 'originalValue')
        ? context.originalValue
        : null;

      if (isDate(originalValue)) {
        return true;
      }

      if (!isString(originalValue)) {
        return false;
      }

      return DateTime.fromFormat(originalValue, format).isValid;
    },
  });
});

yup.addMethod(yup.number, 'money', function method(this: yup.NumberSchema) {
  return this.test(
    'money',
    { key: 'This field must have at most two decimal places' },
    (value) => {
      if (isAbsent(value)) {
        return true;
      }

      return /^\d+(\.\d{1,2})?$/.test(value.toString());
    },
  );
});

export default {};
