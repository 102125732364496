import findIndex from 'lodash/findIndex';

import type { DrawerQueryVariables } from '@virtuslab/nfs-shared/src/schema/admin';

import { toTree } from '..';
import type { Drawer, FlatDrawers } from '../../../../models/Drawers';

const queryResolver = (drawers: FlatDrawers, variables: DrawerQueryVariables): Drawer | null => {
  const drawerIndex = findIndex(drawers, { id: variables.id });

  if (drawerIndex === -1) {
    return null;
  }

  return toTree(drawers.slice(drawerIndex));
};

export default queryResolver;
