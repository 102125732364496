import isNil from 'lodash/isNil';

import type { PendingStateDescriptionFragment, PendingStateDescriptionTypenames } from '@virtuslab/nfs-shared/src/schema/admin';
import type { Nullable } from '@virtuslab/nfs-shared/src/services/object';

export enum PendingStateDescription {
  ACCOUNTINGDATAMISSING = 'AccountingDataMissing',
  INVOICEMISSING = 'InvoiceMissing',
  INVOICEUNCHECKED = 'InvoiceUnchecked',
  NOBANKACCOUNT = 'NoBankAccount',
  TIMEREPORTINVALID = 'TimeReportInvalid',
  WHITELISTINVALID = 'WhiteListInvalid',
  INVOICEFILEMISSING = 'InvoiceFileMissing',
}

export type PendingStateDescriptionType = typeof PendingStateDescription[keyof typeof PendingStateDescription];

const pendingStateDescriptionTypeToEnum: Record<
typeof PendingStateDescriptionTypenames[number], PendingStateDescriptionType> = {
  AccountingDataMissing: PendingStateDescription.ACCOUNTINGDATAMISSING,
  InvoiceMissing: PendingStateDescription.INVOICEMISSING,
  InvoiceUnchecked: PendingStateDescription.INVOICEUNCHECKED,
  NoBankAccount: PendingStateDescription.NOBANKACCOUNT,
  TimeReportInvalid: PendingStateDescription.TIMEREPORTINVALID,
  WhiteListInvalid: PendingStateDescription.WHITELISTINVALID,
  InvoiceFileMissing: PendingStateDescription.INVOICEFILEMISSING,
};

export const convertPendingState = (
  state: Nullable<PendingStateDescriptionFragment>,
): Nullable<PendingStateDescriptionType> => {
  const typename = state?.__typename;
  if (isNil(typename)) {
    return null;
  }

  return pendingStateDescriptionTypeToEnum[typename];
};
