/* eslint-disable */
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// use `yarn generate:graphql to regenerate


      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AbsenceRecordOrNfsError": [
      "AbsenceRecord",
      "NfsError"
    ],
    "AbstractDrawer": [
      "AddClientContractDrawer",
      "AddCompanyDrawer",
      "AddInternalProjectDrawer",
      "AddPersonDrawer",
      "AddProjectDrawer",
      "AddSpaceDrawer",
      "AllBonusesDrawer",
      "AnnualLeaveEntitlementDrawer",
      "AnnualLeavePreferencesDrawer",
      "AnnualLeaveSummaryDrawer",
      "AssignBenefitDrawer",
      "B2BContractDraftDrawer",
      "B2BContractNoteDrawer",
      "B2BPaymentDetailsDrawer",
      "B2BSalaryDetailsDrawer",
      "BasicFinancialReportDrawer",
      "BenefitInvoicesReportDrawer",
      "BenefitNoteDrawer",
      "BonusDrawer",
      "BonusPreviewDrawer",
      "BusinessTripsReportDrawer",
      "ClientInvoicingReportDrawer",
      "CompanyBankAccountDrawer",
      "CompanyDetailsDrawer",
      "CompanyTypeDrawer",
      "ContractBankAccountDrawer",
      "ContractDetailsDrawer",
      "CostSummariesDrawer",
      "CreateBenefitDrawer",
      "CreateSubBenefitDrawer",
      "CreateVariantDrawer",
      "DirectReportingDrawer",
      "EditBenefitDrawer",
      "EditBenefitVariantDrawer",
      "EditBenefitVariantPriceDrawer",
      "EditClientContractDrawer",
      "EditProfileDetailsDrawer",
      "EditProjectDrawer",
      "EditSpaceDrawer",
      "EmploymentBankAccountDrawer",
      "EmploymentContractDraftDrawer",
      "EmploymentContractNoteDrawer",
      "EmploymentPaymentDetailsDrawer",
      "EmploymentSalaryDetailsDrawer",
      "InvoicesDrawer",
      "PeopleListReportDrawer",
      "ProjectBillablesReportDrawer",
      "ProjectMembersDrawer",
      "RateCardDrawer",
      "RecurringBonusDrawer",
      "RecurringBonusPaymentHistoryDrawer",
      "SetFutureEntitlementDrawer",
      "SetRateAndProgressionDrawer",
      "SubcontractDraftDrawer",
      "SubcontractNoteDrawer",
      "TimeReportsReportDrawer",
      "UopHolidaysReportDrawer",
      "UserProjectsDrawer",
      "YearlyCostSummaryDrawer"
    ],
    "AdminOrNfsError": [
      "Admin",
      "NfsError"
    ],
    "AnnualLeaveSummaryOrNfsError": [
      "AnnualLeaveSummary",
      "NfsError"
    ],
    "AssignedVariantOrNfsError": [
      "AssignedVariant",
      "NfsError"
    ],
    "AuditAction": [
      "ApplyPropertyActivity",
      "ApplyPropertyListActivity",
      "CancelPropertyActivity",
      "CancelPropertyListActivity",
      "CommentAction",
      "EditPropertyActivity",
      "EntityAction",
      "PropertyListAction",
      "PropertySetAction",
      "SchedulePropertyActivity",
      "SchedulePropertyListActivity"
    ],
    "AuditEntry": [
      "AbsenceRecordEntry",
      "BenefitVariantAuditEntry",
      "ClientContractAuditEntry",
      "CompanyAuditEntry",
      "ContractAuditEntry",
      "OnCallRecordEntry",
      "ProjectAuditEntry",
      "SpaceAuditEntry",
      "UserAuditEntry",
      "WorkRecordEntry"
    ],
    "AuditEntryResponseOrNfsError": [
      "AuditEntryResponse",
      "NfsError"
    ],
    "AuditedFieldWrapper": [
      "AbsenceRecordAuditFieldWrapper",
      "BenefitVariantAuditFieldWrapper",
      "ClientContractAuditFieldWrapper",
      "CompanyAuditFieldWrapper",
      "ContractAuditFieldWrapper",
      "OnCallRecordAuditFieldWrapper",
      "ProjectAuditFieldWrapper",
      "SpaceAuditFieldWrapper",
      "UserAuditFieldWrapper",
      "WorkRecordAuditFieldWrapper"
    ],
    "B2BContractDraftOrNfsError": [
      "B2BContractDraft",
      "NfsError"
    ],
    "B2BContractOrNfsError": [
      "B2BContract",
      "NfsError"
    ],
    "BankAccountNumber": [
      "Iban",
      "OtherBankAccountNumber"
    ],
    "BankAccountResponseOrNfsError": [
      "BankAccountResponse",
      "NfsError"
    ],
    "BankAccount__OrUnauthorized": [
      "AccessGranted__BankAccount",
      "Unauthorized"
    ],
    "BankAccountsResponseOrNfsError": [
      "BankAccountsResponse",
      "NfsError"
    ],
    "BaseInfoOrNfsError": [
      "BaseInfo",
      "NfsError"
    ],
    "BenefitAdditionalPeople": [
      "BenefitAdditionalAdults",
      "BenefitAdditionalFamily",
      "BenefitAdditionalKids"
    ],
    "BenefitCommon": [
      "Benefit",
      "SubBenefit"
    ],
    "BenefitCommonResponseOrNfsError": [
      "BenefitCommonResponse",
      "NfsError"
    ],
    "BenefitOrNfsError": [
      "Benefit",
      "NfsError"
    ],
    "BenefitVariantOrNfsError": [
      "BenefitVariant",
      "NfsError"
    ],
    "Bonus": [
      "AppreciationBonus",
      "EmployeeReferralBonus",
      "LanguageLearningBonus",
      "OtherBonus",
      "RemoteWorkBonus",
      "RemoteWorkEquipmentBonus",
      "TrainingPackageBonus"
    ],
    "BonusResponseOrNfsError": [
      "BonusResponse",
      "NfsError"
    ],
    "Bonuses__OrUnauthorized": [
      "AccessGranted__Bonuses",
      "Unauthorized"
    ],
    "BooleanResponseOrNfsError": [
      "BooleanResponse",
      "NfsError"
    ],
    "Boolean__OrUnauthorized": [
      "AccessGranted__Boolean",
      "Unauthorized"
    ],
    "ClientContractOrNfsError": [
      "ClientContract",
      "NfsError"
    ],
    "ClientContract__OrUnauthorized": [
      "AccessGranted__ClientContract",
      "Unauthorized"
    ],
    "ClientPositionOrNfsError": [
      "ClientPosition",
      "NfsError"
    ],
    "ClientPosition__OrUnauthorized": [
      "AccessGranted__ClientPosition",
      "Unauthorized"
    ],
    "ClosedEndLocalDateRangeOrNfsError": [
      "ClosedEndLocalDateRange",
      "NfsError"
    ],
    "CompanyInfoOrNfsError": [
      "CompanyInfo",
      "NfsError"
    ],
    "CompanyOrNfsError": [
      "Company",
      "NfsError"
    ],
    "Company__OrUnauthorized": [
      "AccessGranted__Company",
      "Unauthorized"
    ],
    "Contract": [
      "B2BContract",
      "EmploymentContract",
      "Subcontract"
    ],
    "ContractDraft": [
      "B2BContractDraft",
      "EmploymentContractDraft",
      "SubcontractDraft"
    ],
    "ContractDraftResponseOrNfsError": [
      "ContractDraftResponse",
      "NfsError"
    ],
    "ContractResponseOrNfsError": [
      "ContractResponse",
      "NfsError"
    ],
    "ContractSalaryDetails": [
      "B2BContractSalaryDetails",
      "EmploymentContractSalaryDetails"
    ],
    "ContractSalaryDetailsAdditionalDataOrNfsError": [
      "ContractSalaryDetailsAdditionalData",
      "NfsError"
    ],
    "ContractSalaryDetailsResponseOrNfsError": [
      "ContractSalaryDetailsResponse",
      "NfsError"
    ],
    "DownloadableFileUrlOrNfsError": [
      "DownloadableFileUrl",
      "NfsError"
    ],
    "DraftPreviewResponseOrNfsError": [
      "DraftPreviewResponse",
      "NfsError"
    ],
    "EmploymentContractDraftOrNfsError": [
      "EmploymentContractDraft",
      "NfsError"
    ],
    "EmploymentContractOrNfsError": [
      "EmploymentContract",
      "NfsError"
    ],
    "FSPResponseAuditEntryOrNfsError": [
      "FSPResponseAuditEntry",
      "NfsError"
    ],
    "FSPResponseB2BContractSalaryDetailsOrNfsError": [
      "FSPResponseB2BContractSalaryDetails",
      "NfsError"
    ],
    "FSPResponseClientContractOrNfsError": [
      "FSPResponseClientContract",
      "NfsError"
    ],
    "FSPResponseCompanyOrNfsError": [
      "FSPResponseCompany",
      "NfsError"
    ],
    "FSPResponseContractOrNfsError": [
      "FSPResponseContract",
      "NfsError"
    ],
    "FSPResponseEmploymentContractSalaryDetailsOrNfsError": [
      "FSPResponseEmploymentContractSalaryDetails",
      "NfsError"
    ],
    "FSPResponseProjectOrNfsError": [
      "FSPResponseProject",
      "NfsError"
    ],
    "FSPResponseRevenueAdministrationOfficeOrNfsError": [
      "FSPResponseRevenueAdministrationOffice",
      "NfsError"
    ],
    "FSPResponseSpaceOrNfsError": [
      "FSPResponseSpace",
      "NfsError"
    ],
    "FSPResponseUserOrNfsError": [
      "FSPResponseUser",
      "NfsError"
    ],
    "GoogleSpreadsheetUrlOrNfsError": [
      "GoogleSpreadsheetUrl",
      "NfsError"
    ],
    "Highlight": [
      "TimeBasedHighlight"
    ],
    "InvoiceDetailsOrNfsError": [
      "InvoiceDetails",
      "NfsError"
    ],
    "LeadAssignemntOrNfsError": [
      "LeadAssignemnt",
      "NfsError"
    ],
    "LeaveBalanceOrNfsError": [
      "LeaveBalance",
      "NfsError"
    ],
    "ListOfAbsenceRecordResponseOrNfsError": [
      "ListOfAbsenceRecordResponse",
      "NfsError"
    ],
    "ListOfAdminResponseOrNfsError": [
      "ListOfAdminResponse",
      "NfsError"
    ],
    "ListOfAssignedProjectResponseOrNfsError": [
      "ListOfAssignedProjectResponse",
      "NfsError"
    ],
    "ListOfAssignedVariantResponseOrNfsError": [
      "ListOfAssignedVariantResponse",
      "NfsError"
    ],
    "ListOfAssignedVariant__OrUnauthorized": [
      "AccessGranted__ListOfAssignedVariant",
      "Unauthorized"
    ],
    "ListOfBenefitGroupResponseOrNfsError": [
      "ListOfBenefitGroupResponse",
      "NfsError"
    ],
    "ListOfClientContract__OrUnauthorized": [
      "AccessGranted__ListOfClientContract",
      "Unauthorized"
    ],
    "ListOfClientPosition__OrUnauthorized": [
      "AccessGranted__ListOfClientPosition",
      "Unauthorized"
    ],
    "ListOfContractDocumentTemplateDescriptionResponseOrNfsError": [
      "ListOfContractDocumentTemplateDescriptionResponse",
      "NfsError"
    ],
    "ListOfContractDraftResponseOrNfsError": [
      "ListOfContractDraftResponse",
      "NfsError"
    ],
    "ListOfContractSalaryDetailsResponseOrNfsError": [
      "ListOfContractSalaryDetailsResponse",
      "NfsError"
    ],
    "ListOfContract__OrUnauthorized": [
      "AccessGranted__ListOfContract",
      "Unauthorized"
    ],
    "ListOfGoogleFileUrlResponseOrNfsError": [
      "ListOfGoogleFileUrlResponse",
      "NfsError"
    ],
    "ListOfIncomeDetails__OrUnauthorized": [
      "AccessGranted__ListOfIncomeDetails",
      "Unauthorized"
    ],
    "ListOfKVWorkTagIdMinutesResponseOrNfsError": [
      "ListOfKVWorkTagIdMinutesResponse",
      "NfsError"
    ],
    "ListOfOfficeResponseOrNfsError": [
      "ListOfOfficeResponse",
      "NfsError"
    ],
    "ListOfOnCallRecordResponseOrNfsError": [
      "ListOfOnCallRecordResponse",
      "NfsError"
    ],
    "ListOfProjectAssignment__OrUnauthorized": [
      "AccessGranted__ListOfProjectAssignment",
      "Unauthorized"
    ],
    "ListOfProjectIdResponseOrNfsError": [
      "ListOfProjectIdResponse",
      "NfsError"
    ],
    "ListOfReportingPeriodResponseOrNfsError": [
      "ListOfReportingPeriodResponse",
      "NfsError"
    ],
    "ListOfTimeReportValidationResponseOrNfsError": [
      "ListOfTimeReportValidationResponse",
      "NfsError"
    ],
    "ListOfTransfersSummariesResponseOrNfsError": [
      "ListOfTransfersSummariesResponse",
      "NfsError"
    ],
    "ListOfWorkRecordResponseOrNfsError": [
      "ListOfWorkRecordResponse",
      "NfsError"
    ],
    "LocalDate__OrUnauthorized": [
      "AccessGranted__LocalDate",
      "Unauthorized"
    ],
    "OnCallMultipliersOrNfsError": [
      "NfsError",
      "OnCallMultipliers"
    ],
    "OnCallRecordOrNfsError": [
      "NfsError",
      "OnCallRecord"
    ],
    "OneTimeBonus": [
      "AppreciationBonus",
      "EmployeeReferralBonus",
      "LanguageLearningBonus",
      "OtherBonus",
      "RemoteWorkEquipmentBonus",
      "TrainingPackageBonus"
    ],
    "OptionalAddress__OrUnauthorized": [
      "AccessGranted__OptionalAddress",
      "Unauthorized"
    ],
    "OptionalAnnualLeaveEntitlement__OrUnauthorized": [
      "AccessGranted__OptionalAnnualLeaveEntitlement",
      "Unauthorized"
    ],
    "OptionalBankAccount__OrUnauthorized": [
      "AccessGranted__OptionalBankAccount",
      "Unauthorized"
    ],
    "OptionalClientPosition__OrUnauthorized": [
      "AccessGranted__OptionalClientPosition",
      "Unauthorized"
    ],
    "OptionalContractDraft__OrUnauthorized": [
      "AccessGranted__OptionalContractDraft",
      "Unauthorized"
    ],
    "OptionalCountryCode__OrUnauthorized": [
      "AccessGranted__OptionalCountryCode",
      "Unauthorized"
    ],
    "OptionalDays__OrUnauthorized": [
      "AccessGranted__OptionalDays",
      "Unauthorized"
    ],
    "OptionalEmailAddress__OrUnauthorized": [
      "AccessGranted__OptionalEmailAddress",
      "Unauthorized"
    ],
    "OptionalIDType__OrUnauthorized": [
      "AccessGranted__OptionalIDType",
      "Unauthorized"
    ],
    "OptionalLocalDate__OrUnauthorized": [
      "AccessGranted__OptionalLocalDate",
      "Unauthorized"
    ],
    "OptionalOffsetDateTime__OrUnauthorized": [
      "AccessGranted__OptionalOffsetDateTime",
      "Unauthorized"
    ],
    "OptionalPESEL__OrUnauthorized": [
      "AccessGranted__OptionalPESEL",
      "Unauthorized"
    ],
    "OptionalPhoneNumber__OrUnauthorized": [
      "AccessGranted__OptionalPhoneNumber",
      "Unauthorized"
    ],
    "OptionalRevenueAdministrationOffice__OrUnauthorized": [
      "AccessGranted__OptionalRevenueAdministrationOffice",
      "Unauthorized"
    ],
    "OptionalString__OrUnauthorized": [
      "AccessGranted__OptionalString",
      "Unauthorized"
    ],
    "OptionalURI__OrUnauthorized": [
      "AccessGranted__OptionalURI",
      "Unauthorized"
    ],
    "PaymentStatusDetails": [
      "Done",
      "Pending",
      "Ready"
    ],
    "PendingStateDescription": [
      "AccountingDataMissing",
      "InvoiceFileMissing",
      "InvoiceMissing",
      "InvoiceUnchecked",
      "NoBankAccount",
      "TimeReportInvalid",
      "WhiteListInvalid"
    ],
    "PolishVatOrNfsError": [
      "NfsError",
      "PolishVat"
    ],
    "ProjectAssignmentOrNfsError": [
      "NfsError",
      "ProjectAssignment"
    ],
    "ProjectOrNfsError": [
      "NfsError",
      "Project"
    ],
    "RecurringBonus": [
      "RemoteWorkBonus"
    ],
    "SpaceOrNfsError": [
      "NfsError",
      "Space"
    ],
    "String__OrUnauthorized": [
      "AccessGranted__String",
      "Unauthorized"
    ],
    "SubBenefitOrNfsError": [
      "NfsError",
      "SubBenefit"
    ],
    "SubcontractDraftOrNfsError": [
      "NfsError",
      "SubcontractDraft"
    ],
    "SubcontractOrNfsError": [
      "NfsError",
      "Subcontract"
    ],
    "TimeBasedBoolean__OrUnauthorized": [
      "AccessGranted__TimeBasedBoolean",
      "Unauthorized"
    ],
    "TimeBasedCurrency__OrUnauthorized": [
      "AccessGranted__TimeBasedCurrency",
      "Unauthorized"
    ],
    "TimeBasedOptClearableBasisPoint__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableBasisPoint",
      "Unauthorized"
    ],
    "TimeBasedOptClearableDeductibleCostsType__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableDeductibleCostsType",
      "Unauthorized"
    ],
    "TimeBasedOptClearableNetGross__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableNetGross",
      "Unauthorized"
    ],
    "TimeBasedOptClearableOnCallRate__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableOnCallRate",
      "Unauthorized"
    ],
    "TimeBasedOptCurrency__OrUnauthorized": [
      "AccessGranted__TimeBasedOptCurrency",
      "Unauthorized"
    ],
    "TimeBasedRate__OrUnauthorized": [
      "AccessGranted__TimeBasedRate",
      "Unauthorized"
    ],
    "TimeBasedVatType__OrUnauthorized": [
      "AccessGranted__TimeBasedVatType",
      "Unauthorized"
    ],
    "TimeBasedWorkingTime__OrUnauthorized": [
      "AccessGranted__TimeBasedWorkingTime",
      "Unauthorized"
    ],
    "TimeReportOrNfsError": [
      "NfsError",
      "TimeReport"
    ],
    "TimeReportStats": [
      "B2BTimeReportStats",
      "EmploymentTimeReportStats"
    ],
    "TimeReportValidation": [
      "IncompleteEntries",
      "MissingEntries",
      "NegativeFlexTimeBalance"
    ],
    "URIResponseOrNfsError": [
      "NfsError",
      "URIResponse"
    ],
    "UnitResponseOrNfsError": [
      "NfsError",
      "UnitResponse"
    ],
    "UserError": [
      "ActiveReportingRangeError",
      "AggregatedValidationErrors",
      "DuplicatedEntityError",
      "EmptyRequestInputError",
      "EntitiesNotFoundError",
      "EntityNotFoundError",
      "EntityVersionMismatched",
      "ForeignKeyConstraintError",
      "IllegalPositionRemovalError",
      "IncompleteArgumentError",
      "IncompleteArgumentsError",
      "InternalNfsError",
      "InvalidArgumentsError",
      "InvalidCompanyTypeError",
      "InvalidContentTypeError",
      "InvalidCursorError",
      "InvalidEmailAddress",
      "InvalidStateError",
      "InvoiceAlreadyAcceptedError",
      "LeaveEntitlementExceededError",
      "MissingFileMetadataError",
      "NBPBaseCurrencyError",
      "OperationNotAvailableError",
      "OtherInvalidRequestError",
      "PaymentCouldNotBeChangedError",
      "PositionInactiveError",
      "TimeBasedActivityOverlapError",
      "TimeBasedEffectiveFromInPastError",
      "TimeReportOverlapError",
      "UnauthorizedOperationError",
      "UnknownContentTypeError",
      "UserFacingAuthzError",
      "ValidationError"
    ],
    "UserOrNfsError": [
      "NfsError",
      "User"
    ],
    "VatType": [
      "VatApplied",
      "VatNotApplied"
    ],
    "VerificationStateBankAccountStatus": [
      "NotVerifiedBankAccountStatus",
      "VerifiedBankAccountStatus"
    ],
    "VerificationStateEuVatType": [
      "NotVerifiedEuVatType",
      "VerifiedEuVatType"
    ],
    "VerificationStateEuVatTypeResponseOrNfsError": [
      "NfsError",
      "VerificationStateEuVatTypeResponse"
    ],
    "VerificationStateWithDescriptionBooleanVATStatus": [
      "NotVerifiedBooleanVATStatus",
      "VerifiedWithDescriptionBooleanVATStatus"
    ],
    "WorkRecordOrNfsError": [
      "NfsError",
      "WorkRecord"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AbsenceRecordOrNfsError": [
      "AbsenceRecord",
      "NfsError"
    ],
    "AbstractDrawer": [
      "AddClientContractDrawer",
      "AddCompanyDrawer",
      "AddInternalProjectDrawer",
      "AddPersonDrawer",
      "AddProjectDrawer",
      "AddSpaceDrawer",
      "AllBonusesDrawer",
      "AnnualLeaveEntitlementDrawer",
      "AnnualLeavePreferencesDrawer",
      "AnnualLeaveSummaryDrawer",
      "AssignBenefitDrawer",
      "B2BContractDraftDrawer",
      "B2BContractNoteDrawer",
      "B2BPaymentDetailsDrawer",
      "B2BSalaryDetailsDrawer",
      "BasicFinancialReportDrawer",
      "BenefitInvoicesReportDrawer",
      "BenefitNoteDrawer",
      "BonusDrawer",
      "BonusPreviewDrawer",
      "BusinessTripsReportDrawer",
      "ClientInvoicingReportDrawer",
      "CompanyBankAccountDrawer",
      "CompanyDetailsDrawer",
      "CompanyTypeDrawer",
      "ContractBankAccountDrawer",
      "ContractDetailsDrawer",
      "CostSummariesDrawer",
      "CreateBenefitDrawer",
      "CreateSubBenefitDrawer",
      "CreateVariantDrawer",
      "DirectReportingDrawer",
      "EditBenefitDrawer",
      "EditBenefitVariantDrawer",
      "EditBenefitVariantPriceDrawer",
      "EditClientContractDrawer",
      "EditProfileDetailsDrawer",
      "EditProjectDrawer",
      "EditSpaceDrawer",
      "EmploymentBankAccountDrawer",
      "EmploymentContractDraftDrawer",
      "EmploymentContractNoteDrawer",
      "EmploymentPaymentDetailsDrawer",
      "EmploymentSalaryDetailsDrawer",
      "InvoicesDrawer",
      "PeopleListReportDrawer",
      "ProjectBillablesReportDrawer",
      "ProjectMembersDrawer",
      "RateCardDrawer",
      "RecurringBonusDrawer",
      "RecurringBonusPaymentHistoryDrawer",
      "SetFutureEntitlementDrawer",
      "SetRateAndProgressionDrawer",
      "SubcontractDraftDrawer",
      "SubcontractNoteDrawer",
      "TimeReportsReportDrawer",
      "UopHolidaysReportDrawer",
      "UserProjectsDrawer",
      "YearlyCostSummaryDrawer"
    ],
    "AdminOrNfsError": [
      "Admin",
      "NfsError"
    ],
    "AnnualLeaveSummaryOrNfsError": [
      "AnnualLeaveSummary",
      "NfsError"
    ],
    "AssignedVariantOrNfsError": [
      "AssignedVariant",
      "NfsError"
    ],
    "AuditAction": [
      "ApplyPropertyActivity",
      "ApplyPropertyListActivity",
      "CancelPropertyActivity",
      "CancelPropertyListActivity",
      "CommentAction",
      "EditPropertyActivity",
      "EntityAction",
      "PropertyListAction",
      "PropertySetAction",
      "SchedulePropertyActivity",
      "SchedulePropertyListActivity"
    ],
    "AuditEntry": [
      "AbsenceRecordEntry",
      "BenefitVariantAuditEntry",
      "ClientContractAuditEntry",
      "CompanyAuditEntry",
      "ContractAuditEntry",
      "OnCallRecordEntry",
      "ProjectAuditEntry",
      "SpaceAuditEntry",
      "UserAuditEntry",
      "WorkRecordEntry"
    ],
    "AuditEntryResponseOrNfsError": [
      "AuditEntryResponse",
      "NfsError"
    ],
    "AuditedFieldWrapper": [
      "AbsenceRecordAuditFieldWrapper",
      "BenefitVariantAuditFieldWrapper",
      "ClientContractAuditFieldWrapper",
      "CompanyAuditFieldWrapper",
      "ContractAuditFieldWrapper",
      "OnCallRecordAuditFieldWrapper",
      "ProjectAuditFieldWrapper",
      "SpaceAuditFieldWrapper",
      "UserAuditFieldWrapper",
      "WorkRecordAuditFieldWrapper"
    ],
    "B2BContractDraftOrNfsError": [
      "B2BContractDraft",
      "NfsError"
    ],
    "B2BContractOrNfsError": [
      "B2BContract",
      "NfsError"
    ],
    "BankAccountNumber": [
      "Iban",
      "OtherBankAccountNumber"
    ],
    "BankAccountResponseOrNfsError": [
      "BankAccountResponse",
      "NfsError"
    ],
    "BankAccount__OrUnauthorized": [
      "AccessGranted__BankAccount",
      "Unauthorized"
    ],
    "BankAccountsResponseOrNfsError": [
      "BankAccountsResponse",
      "NfsError"
    ],
    "BaseInfoOrNfsError": [
      "BaseInfo",
      "NfsError"
    ],
    "BenefitAdditionalPeople": [
      "BenefitAdditionalAdults",
      "BenefitAdditionalFamily",
      "BenefitAdditionalKids"
    ],
    "BenefitCommon": [
      "Benefit",
      "SubBenefit"
    ],
    "BenefitCommonResponseOrNfsError": [
      "BenefitCommonResponse",
      "NfsError"
    ],
    "BenefitOrNfsError": [
      "Benefit",
      "NfsError"
    ],
    "BenefitVariantOrNfsError": [
      "BenefitVariant",
      "NfsError"
    ],
    "Bonus": [
      "AppreciationBonus",
      "EmployeeReferralBonus",
      "LanguageLearningBonus",
      "OtherBonus",
      "RemoteWorkBonus",
      "RemoteWorkEquipmentBonus",
      "TrainingPackageBonus"
    ],
    "BonusResponseOrNfsError": [
      "BonusResponse",
      "NfsError"
    ],
    "Bonuses__OrUnauthorized": [
      "AccessGranted__Bonuses",
      "Unauthorized"
    ],
    "BooleanResponseOrNfsError": [
      "BooleanResponse",
      "NfsError"
    ],
    "Boolean__OrUnauthorized": [
      "AccessGranted__Boolean",
      "Unauthorized"
    ],
    "ClientContractOrNfsError": [
      "ClientContract",
      "NfsError"
    ],
    "ClientContract__OrUnauthorized": [
      "AccessGranted__ClientContract",
      "Unauthorized"
    ],
    "ClientPositionOrNfsError": [
      "ClientPosition",
      "NfsError"
    ],
    "ClientPosition__OrUnauthorized": [
      "AccessGranted__ClientPosition",
      "Unauthorized"
    ],
    "ClosedEndLocalDateRangeOrNfsError": [
      "ClosedEndLocalDateRange",
      "NfsError"
    ],
    "CompanyInfoOrNfsError": [
      "CompanyInfo",
      "NfsError"
    ],
    "CompanyOrNfsError": [
      "Company",
      "NfsError"
    ],
    "Company__OrUnauthorized": [
      "AccessGranted__Company",
      "Unauthorized"
    ],
    "Contract": [
      "B2BContract",
      "EmploymentContract",
      "Subcontract"
    ],
    "ContractDraft": [
      "B2BContractDraft",
      "EmploymentContractDraft",
      "SubcontractDraft"
    ],
    "ContractDraftResponseOrNfsError": [
      "ContractDraftResponse",
      "NfsError"
    ],
    "ContractResponseOrNfsError": [
      "ContractResponse",
      "NfsError"
    ],
    "ContractSalaryDetails": [
      "B2BContractSalaryDetails",
      "EmploymentContractSalaryDetails"
    ],
    "ContractSalaryDetailsAdditionalDataOrNfsError": [
      "ContractSalaryDetailsAdditionalData",
      "NfsError"
    ],
    "ContractSalaryDetailsResponseOrNfsError": [
      "ContractSalaryDetailsResponse",
      "NfsError"
    ],
    "DownloadableFileUrlOrNfsError": [
      "DownloadableFileUrl",
      "NfsError"
    ],
    "DraftPreviewResponseOrNfsError": [
      "DraftPreviewResponse",
      "NfsError"
    ],
    "EmploymentContractDraftOrNfsError": [
      "EmploymentContractDraft",
      "NfsError"
    ],
    "EmploymentContractOrNfsError": [
      "EmploymentContract",
      "NfsError"
    ],
    "FSPResponseAuditEntryOrNfsError": [
      "FSPResponseAuditEntry",
      "NfsError"
    ],
    "FSPResponseB2BContractSalaryDetailsOrNfsError": [
      "FSPResponseB2BContractSalaryDetails",
      "NfsError"
    ],
    "FSPResponseClientContractOrNfsError": [
      "FSPResponseClientContract",
      "NfsError"
    ],
    "FSPResponseCompanyOrNfsError": [
      "FSPResponseCompany",
      "NfsError"
    ],
    "FSPResponseContractOrNfsError": [
      "FSPResponseContract",
      "NfsError"
    ],
    "FSPResponseEmploymentContractSalaryDetailsOrNfsError": [
      "FSPResponseEmploymentContractSalaryDetails",
      "NfsError"
    ],
    "FSPResponseProjectOrNfsError": [
      "FSPResponseProject",
      "NfsError"
    ],
    "FSPResponseRevenueAdministrationOfficeOrNfsError": [
      "FSPResponseRevenueAdministrationOffice",
      "NfsError"
    ],
    "FSPResponseSpaceOrNfsError": [
      "FSPResponseSpace",
      "NfsError"
    ],
    "FSPResponseUserOrNfsError": [
      "FSPResponseUser",
      "NfsError"
    ],
    "GoogleSpreadsheetUrlOrNfsError": [
      "GoogleSpreadsheetUrl",
      "NfsError"
    ],
    "Highlight": [
      "TimeBasedHighlight"
    ],
    "InvoiceDetailsOrNfsError": [
      "InvoiceDetails",
      "NfsError"
    ],
    "LeadAssignemntOrNfsError": [
      "LeadAssignemnt",
      "NfsError"
    ],
    "LeaveBalanceOrNfsError": [
      "LeaveBalance",
      "NfsError"
    ],
    "ListOfAbsenceRecordResponseOrNfsError": [
      "ListOfAbsenceRecordResponse",
      "NfsError"
    ],
    "ListOfAdminResponseOrNfsError": [
      "ListOfAdminResponse",
      "NfsError"
    ],
    "ListOfAssignedProjectResponseOrNfsError": [
      "ListOfAssignedProjectResponse",
      "NfsError"
    ],
    "ListOfAssignedVariantResponseOrNfsError": [
      "ListOfAssignedVariantResponse",
      "NfsError"
    ],
    "ListOfAssignedVariant__OrUnauthorized": [
      "AccessGranted__ListOfAssignedVariant",
      "Unauthorized"
    ],
    "ListOfBenefitGroupResponseOrNfsError": [
      "ListOfBenefitGroupResponse",
      "NfsError"
    ],
    "ListOfClientContract__OrUnauthorized": [
      "AccessGranted__ListOfClientContract",
      "Unauthorized"
    ],
    "ListOfClientPosition__OrUnauthorized": [
      "AccessGranted__ListOfClientPosition",
      "Unauthorized"
    ],
    "ListOfContractDocumentTemplateDescriptionResponseOrNfsError": [
      "ListOfContractDocumentTemplateDescriptionResponse",
      "NfsError"
    ],
    "ListOfContractDraftResponseOrNfsError": [
      "ListOfContractDraftResponse",
      "NfsError"
    ],
    "ListOfContractSalaryDetailsResponseOrNfsError": [
      "ListOfContractSalaryDetailsResponse",
      "NfsError"
    ],
    "ListOfContract__OrUnauthorized": [
      "AccessGranted__ListOfContract",
      "Unauthorized"
    ],
    "ListOfGoogleFileUrlResponseOrNfsError": [
      "ListOfGoogleFileUrlResponse",
      "NfsError"
    ],
    "ListOfIncomeDetails__OrUnauthorized": [
      "AccessGranted__ListOfIncomeDetails",
      "Unauthorized"
    ],
    "ListOfKVWorkTagIdMinutesResponseOrNfsError": [
      "ListOfKVWorkTagIdMinutesResponse",
      "NfsError"
    ],
    "ListOfOfficeResponseOrNfsError": [
      "ListOfOfficeResponse",
      "NfsError"
    ],
    "ListOfOnCallRecordResponseOrNfsError": [
      "ListOfOnCallRecordResponse",
      "NfsError"
    ],
    "ListOfProjectAssignment__OrUnauthorized": [
      "AccessGranted__ListOfProjectAssignment",
      "Unauthorized"
    ],
    "ListOfProjectIdResponseOrNfsError": [
      "ListOfProjectIdResponse",
      "NfsError"
    ],
    "ListOfReportingPeriodResponseOrNfsError": [
      "ListOfReportingPeriodResponse",
      "NfsError"
    ],
    "ListOfTimeReportValidationResponseOrNfsError": [
      "ListOfTimeReportValidationResponse",
      "NfsError"
    ],
    "ListOfTransfersSummariesResponseOrNfsError": [
      "ListOfTransfersSummariesResponse",
      "NfsError"
    ],
    "ListOfWorkRecordResponseOrNfsError": [
      "ListOfWorkRecordResponse",
      "NfsError"
    ],
    "LocalDate__OrUnauthorized": [
      "AccessGranted__LocalDate",
      "Unauthorized"
    ],
    "OnCallMultipliersOrNfsError": [
      "NfsError",
      "OnCallMultipliers"
    ],
    "OnCallRecordOrNfsError": [
      "NfsError",
      "OnCallRecord"
    ],
    "OneTimeBonus": [
      "AppreciationBonus",
      "EmployeeReferralBonus",
      "LanguageLearningBonus",
      "OtherBonus",
      "RemoteWorkEquipmentBonus",
      "TrainingPackageBonus"
    ],
    "OptionalAddress__OrUnauthorized": [
      "AccessGranted__OptionalAddress",
      "Unauthorized"
    ],
    "OptionalAnnualLeaveEntitlement__OrUnauthorized": [
      "AccessGranted__OptionalAnnualLeaveEntitlement",
      "Unauthorized"
    ],
    "OptionalBankAccount__OrUnauthorized": [
      "AccessGranted__OptionalBankAccount",
      "Unauthorized"
    ],
    "OptionalClientPosition__OrUnauthorized": [
      "AccessGranted__OptionalClientPosition",
      "Unauthorized"
    ],
    "OptionalContractDraft__OrUnauthorized": [
      "AccessGranted__OptionalContractDraft",
      "Unauthorized"
    ],
    "OptionalCountryCode__OrUnauthorized": [
      "AccessGranted__OptionalCountryCode",
      "Unauthorized"
    ],
    "OptionalDays__OrUnauthorized": [
      "AccessGranted__OptionalDays",
      "Unauthorized"
    ],
    "OptionalEmailAddress__OrUnauthorized": [
      "AccessGranted__OptionalEmailAddress",
      "Unauthorized"
    ],
    "OptionalIDType__OrUnauthorized": [
      "AccessGranted__OptionalIDType",
      "Unauthorized"
    ],
    "OptionalLocalDate__OrUnauthorized": [
      "AccessGranted__OptionalLocalDate",
      "Unauthorized"
    ],
    "OptionalOffsetDateTime__OrUnauthorized": [
      "AccessGranted__OptionalOffsetDateTime",
      "Unauthorized"
    ],
    "OptionalPESEL__OrUnauthorized": [
      "AccessGranted__OptionalPESEL",
      "Unauthorized"
    ],
    "OptionalPhoneNumber__OrUnauthorized": [
      "AccessGranted__OptionalPhoneNumber",
      "Unauthorized"
    ],
    "OptionalRevenueAdministrationOffice__OrUnauthorized": [
      "AccessGranted__OptionalRevenueAdministrationOffice",
      "Unauthorized"
    ],
    "OptionalString__OrUnauthorized": [
      "AccessGranted__OptionalString",
      "Unauthorized"
    ],
    "OptionalURI__OrUnauthorized": [
      "AccessGranted__OptionalURI",
      "Unauthorized"
    ],
    "PaymentStatusDetails": [
      "Done",
      "Pending",
      "Ready"
    ],
    "PendingStateDescription": [
      "AccountingDataMissing",
      "InvoiceFileMissing",
      "InvoiceMissing",
      "InvoiceUnchecked",
      "NoBankAccount",
      "TimeReportInvalid",
      "WhiteListInvalid"
    ],
    "PolishVatOrNfsError": [
      "NfsError",
      "PolishVat"
    ],
    "ProjectAssignmentOrNfsError": [
      "NfsError",
      "ProjectAssignment"
    ],
    "ProjectOrNfsError": [
      "NfsError",
      "Project"
    ],
    "RecurringBonus": [
      "RemoteWorkBonus"
    ],
    "SpaceOrNfsError": [
      "NfsError",
      "Space"
    ],
    "String__OrUnauthorized": [
      "AccessGranted__String",
      "Unauthorized"
    ],
    "SubBenefitOrNfsError": [
      "NfsError",
      "SubBenefit"
    ],
    "SubcontractDraftOrNfsError": [
      "NfsError",
      "SubcontractDraft"
    ],
    "SubcontractOrNfsError": [
      "NfsError",
      "Subcontract"
    ],
    "TimeBasedBoolean__OrUnauthorized": [
      "AccessGranted__TimeBasedBoolean",
      "Unauthorized"
    ],
    "TimeBasedCurrency__OrUnauthorized": [
      "AccessGranted__TimeBasedCurrency",
      "Unauthorized"
    ],
    "TimeBasedOptClearableBasisPoint__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableBasisPoint",
      "Unauthorized"
    ],
    "TimeBasedOptClearableDeductibleCostsType__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableDeductibleCostsType",
      "Unauthorized"
    ],
    "TimeBasedOptClearableNetGross__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableNetGross",
      "Unauthorized"
    ],
    "TimeBasedOptClearableOnCallRate__OrUnauthorized": [
      "AccessGranted__TimeBasedOptClearableOnCallRate",
      "Unauthorized"
    ],
    "TimeBasedOptCurrency__OrUnauthorized": [
      "AccessGranted__TimeBasedOptCurrency",
      "Unauthorized"
    ],
    "TimeBasedRate__OrUnauthorized": [
      "AccessGranted__TimeBasedRate",
      "Unauthorized"
    ],
    "TimeBasedVatType__OrUnauthorized": [
      "AccessGranted__TimeBasedVatType",
      "Unauthorized"
    ],
    "TimeBasedWorkingTime__OrUnauthorized": [
      "AccessGranted__TimeBasedWorkingTime",
      "Unauthorized"
    ],
    "TimeReportOrNfsError": [
      "NfsError",
      "TimeReport"
    ],
    "TimeReportStats": [
      "B2BTimeReportStats",
      "EmploymentTimeReportStats"
    ],
    "TimeReportValidation": [
      "IncompleteEntries",
      "MissingEntries",
      "NegativeFlexTimeBalance"
    ],
    "URIResponseOrNfsError": [
      "NfsError",
      "URIResponse"
    ],
    "UnitResponseOrNfsError": [
      "NfsError",
      "UnitResponse"
    ],
    "UserError": [
      "ActiveReportingRangeError",
      "AggregatedValidationErrors",
      "DuplicatedEntityError",
      "EmptyRequestInputError",
      "EntitiesNotFoundError",
      "EntityNotFoundError",
      "EntityVersionMismatched",
      "ForeignKeyConstraintError",
      "IllegalPositionRemovalError",
      "IncompleteArgumentError",
      "IncompleteArgumentsError",
      "InternalNfsError",
      "InvalidArgumentsError",
      "InvalidCompanyTypeError",
      "InvalidContentTypeError",
      "InvalidCursorError",
      "InvalidEmailAddress",
      "InvalidStateError",
      "InvoiceAlreadyAcceptedError",
      "LeaveEntitlementExceededError",
      "MissingFileMetadataError",
      "NBPBaseCurrencyError",
      "OperationNotAvailableError",
      "OtherInvalidRequestError",
      "PaymentCouldNotBeChangedError",
      "PositionInactiveError",
      "TimeBasedActivityOverlapError",
      "TimeBasedEffectiveFromInPastError",
      "TimeReportOverlapError",
      "UnauthorizedOperationError",
      "UnknownContentTypeError",
      "UserFacingAuthzError",
      "ValidationError"
    ],
    "UserOrNfsError": [
      "NfsError",
      "User"
    ],
    "VatType": [
      "VatApplied",
      "VatNotApplied"
    ],
    "VerificationStateBankAccountStatus": [
      "NotVerifiedBankAccountStatus",
      "VerifiedBankAccountStatus"
    ],
    "VerificationStateEuVatType": [
      "NotVerifiedEuVatType",
      "VerifiedEuVatType"
    ],
    "VerificationStateEuVatTypeResponseOrNfsError": [
      "NfsError",
      "VerificationStateEuVatTypeResponse"
    ],
    "VerificationStateWithDescriptionBooleanVATStatus": [
      "NotVerifiedBooleanVATStatus",
      "VerifiedWithDescriptionBooleanVATStatus"
    ],
    "WorkRecordOrNfsError": [
      "NfsError",
      "WorkRecord"
    ]
  }
};
      export default result;
    