import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';
import { withRedirect } from '@virtuslab/nfs-shared/src/services/routes';

import { BenefitsRoutes } from '../../../config/paths';

const BenefitsPage = lazy(async () => import('.'));
const BenefitsProducts = lazy(async () => import('../../organisms/BenefitsProducts'));
const BenefitsSettlements = lazy(async () => import('../../organisms/BenefitsSettlements'));

const config: RoutesConfig<WrapRoutes<typeof BenefitsRoutes>> = {
  paths: {
    [BenefitsRoutes.BENEFITS]: {
      render: withRedirect(BenefitsPage, {
        from: BenefitsRoutes.BENEFITS,
        to: BenefitsRoutes.BENEFITS_PRODUCTS,
      }),
    },
    [BenefitsRoutes.BENEFITS_PRODUCTS]: {
      render: BenefitsProducts,
    },
    [BenefitsRoutes.BENEFITS_SETTLEMENTS]: {
      render: BenefitsSettlements,
    },
  },
};

export default config;
