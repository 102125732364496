import findIndex from 'lodash/findIndex';

import type { AbstractDrawer, AbstractDrawerUnionInterface } from '@virtuslab/nfs-shared/src/schema/admin';
import type { DeepRequired } from '@virtuslab/nfs-shared/src/services/object';

import type { ReactiveVars } from '../../../..';

export default function createUpdateDrawer(drawersVar: ReactiveVars['drawersVar']) {
  return <Drawer extends AbstractDrawerUnionInterface>(
    drawerToUpdate: Drawer,
    newValues: DeepRequired<Omit<Drawer, keyof AbstractDrawer | '__typename'>>,
  ): void => {
    const drawers = drawersVar();
    const updateIndex = findIndex(drawers, { id: drawerToUpdate.id });

    if (updateIndex !== -1) {
      drawersVar([
        ...drawers.slice(0, updateIndex),
        { ...drawers[updateIndex], ...newValues },
        ...drawers.slice(updateIndex + 1),
      ]);
    }
  };
}
