import type { ReactElement } from 'react';
import { useMemo } from 'react';

import { useAuth } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';

import Loading from '@virtuslab/nfs-shared/src/components/molecules/Loading';
import { useFeatureFlagsContext } from '@virtuslab/nfs-shared/src/components/organisms/FeatureFlagsContext';
import RenderRoutes from '@virtuslab/nfs-shared/src/components/organisms/RenderRoutes';
import { removeInaccessibleRoutes } from '@virtuslab/nfs-shared/src/services/security';

import routes from '../../../config/routes';

import { useProfileInfo } from '../ServicesProvider';

const SecureRenderRoutes = (): ReactElement => {
  const profile = useProfileInfo();
  const auth = useAuth();

  const { useACL } = useFeatureFlagsContext();

  const parsedRoutes = useMemo(() => (useACL
    ? removeInaccessibleRoutes(profile?.groups ?? [], routes)
    : routes), [profile, useACL]);

  if (auth.isAuthenticated() && isNil(profile)) {
    return <Loading />;
  }

  return <RenderRoutes routes={parsedRoutes} />;
};

export default SecureRenderRoutes;
