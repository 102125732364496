import type { ReactElement, ReactNode } from 'react';
import { useContext, createContext, useMemo } from 'react';

import { InMemoryCache, makeVar } from '@apollo/client';
import merge from 'lodash/fp/merge';
import noop from 'lodash/noop';

import ApolloProvider from '@virtuslab/nfs-shared/src/components/organisms/ApolloProvider';
import generatedIntrospection from '@virtuslab/nfs-shared/src/schema/admin/fragmentMatcher';
import { scalarTypePolicies } from '@virtuslab/nfs-shared/src/schema/admin/scalarTypePolicies';

import config from '../../../config';

import type { ReactiveVars } from '../../../state';
import { typeDefs, typePolicies } from '../../../state';
import type { FlatDrawers } from '../../../state/models/Drawers';
import { drawerMutations } from '../../../state/operations/mutations';
import type { DrawerMutations } from '../../../state/operations/mutations';

type Props = Readonly<{
  children: ReactNode;
  onUnauthorized: () => void;
}>;

export type ContextValue = Readonly<{
  drawerMutations: DrawerMutations;
}>;

export const context = createContext<ContextValue>({
  drawerMutations: {
    open: noop,
    openChild: noop,
    closeAll: noop,
    update: noop,
    close: noop,
  },
});

const GraphqlProvider = ({ children, onUnauthorized }: Props): ReactElement => {
  const reactiveVars = useMemo<ReactiveVars>(() => ({
    drawersVar: makeVar<FlatDrawers>([]),
  }), []);

  const contextValue = useMemo<ContextValue>(() => ({
    drawerMutations: drawerMutations(reactiveVars.drawersVar),
  }), [reactiveVars]);

  const apolloCache = useMemo(() => new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: merge(scalarTypePolicies, typePolicies(reactiveVars)),
  }), [reactiveVars]);

  return (
    <ApolloProvider onUnauthorized={onUnauthorized} config={config} cache={apolloCache} typeDefs={typeDefs}>
      <context.Provider value={contextValue}>
        {children}
      </context.Provider>
    </ApolloProvider>
  );
};

export const useMutations = (): ContextValue => useContext(context);

export default GraphqlProvider;
