import {
  Users, Suitcase, Gift, ChartBar, SquaresFour, Handshake, Rocket, Planet, Money,
} from 'phosphor-react';

import type { NavGroup, NavItem } from '@virtuslab/nfs-shared/src/config/navigation';

import type { TranslatingFn } from '../services/i18n';

import type { RouteAccessChecker } from './paths';
import {
  BenefitsRoutes,
  CompaniesRoutes,
  CompanyDetailsRoutes,
  ContractsRoutes,
  DashboardRoutes,
  LogoutRoutes,
  PeopleRoutes,
  PersonDetailsRoutes,
  ProjectsRoutes,
  ReportsRoutes,
  SalariesDetailsRoutes,
  SalariesRoutes,
  SettingsRoutes,
  SpaceDetailsRoutes,
  SpacesRoutes,
} from './paths';

const navigation = (t: TranslatingFn, accessChecker: RouteAccessChecker): readonly NavGroup[] => [
  {
    id: 'user',
    items: [
      {
        id: 'dashboard',
        title: t('Dashboard'),
        icon: <SquaresFour />,
        url: DashboardRoutes.DASHBOARD,
        disabled: true,
        canBeAccessed: accessChecker(DashboardRoutes),
      },
    ],
  },
  {
    id: 'hr',
    title: t('Team'),
    items: [
      {
        id: 'people',
        title: t('People'),
        icon: <Users />,
        url: PeopleRoutes.PEOPLE,
        associatedRoutes: Object.values(PersonDetailsRoutes),
        canBeAccessed: accessChecker(PeopleRoutes),
      },
      {
        id: 'contracts',
        title: t('Contracts'),
        icon: <Handshake />,
        url: ContractsRoutes.CONTRACTS,
        canBeAccessed: accessChecker(ContractsRoutes),
      },
      {
        id: 'benefits',
        title: t('Benefits'),
        icon: <Gift />,
        url: BenefitsRoutes.BENEFITS,
        associatedRoutes: Object.values(BenefitsRoutes),
        canBeAccessed: accessChecker(BenefitsRoutes),
      },
    ],
  },
  {
    id: 'management',
    title: t('Management'),
    items: [
      {
        id: 'projects',
        title: t('Projects'),
        icon: <Rocket />,
        url: ProjectsRoutes.PROJECTS,
        canBeAccessed: accessChecker(ProjectsRoutes),
      },
      {
        id: 'company',
        title: t('Companies'),
        icon: <Suitcase />,
        url: CompaniesRoutes.COMPANIES,
        associatedRoutes: Object.values(CompanyDetailsRoutes),
        canBeAccessed: accessChecker(CompaniesRoutes),
      },

      {
        id: 'reports',
        title: t('Reports'),
        icon: <ChartBar />,
        url: ReportsRoutes.REPORTS,
        canBeAccessed: accessChecker(ReportsRoutes),
      },
      {
        id: 'salaries',
        title: t('Salaries'),
        icon: <Money />,
        url: SalariesRoutes.BASE,
        associatedRoutes: [SalariesRoutes.SALARIES, ...Object.values(SalariesDetailsRoutes)],
        canBeAccessed: accessChecker(SalariesRoutes),
      },
      {
        id: 'spaces',
        title: t('Spaces'),
        icon: <Planet />,
        url: SpacesRoutes.SPACES,
        associatedRoutes: Object.values(SpaceDetailsRoutes),
        canBeAccessed: accessChecker(SpacesRoutes),
      },
    ],
  },
];

export const profileNavigation = (t: TranslatingFn): NavItem[] => [
  {
    id: 'settings',
    title: t('Accounts settings'),
    url: SettingsRoutes.SETTINGS,
    disabled: true,
  },
  {
    id: 'logout',
    title: t('Logout'),
    url: LogoutRoutes.LOGOUT,
  },
];

export default navigation;
