import type { ReactElement } from 'react';
import { useEffect, Suspense } from 'react';

import { Secure } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';
import {
  Navigate,
  Outlet,
  useMatch,
  useNavigate,
} from 'react-router-dom';

import Loading from '@virtuslab/nfs-shared/src/components/molecules/Loading';
import ContentErrorBoundary from '@virtuslab/nfs-shared/src/components/organisms/ContentErrorBoundary';
import ToastsContainer from '@virtuslab/nfs-shared/src/components/organisms/ToastsContainer';
import { makeLoginRedirectURL } from '@virtuslab/nfs-shared/src/services/security';

import config from '../../../config';
import { BaseRoutes, LoginRoutes, PeopleRoutes } from '../../../config/paths';

import AccessRightsProvider from '../AccessRightsProvider';
import DrawerStateSyncer from '../DrawerStateSyncer';
import GraphqlProvider from '../GraphqlProvider';
import NavSidebarWrapper from '../NavSidebarWrapper';

const redirectUrl = makeLoginRedirectURL(LoginRoutes.LOGIN, config.baseUrl);

const SecureRoutesWrapper = (): ReactElement => {
  const isInRoot = !isNil(useMatch(BaseRoutes.BASE));
  const navigate = useNavigate();

  useEffect(() => {
    if (isInRoot) {
      navigate(PeopleRoutes.PEOPLE);
    }
  }, [isInRoot, navigate]);

  return (
    <Secure otherwise={<Navigate to={redirectUrl} />}>
      <ContentErrorBoundary>
        <GraphqlProvider onUnauthorized={() => navigate(redirectUrl)}>
          <AccessRightsProvider>
            <ToastsContainer />
            <NavSidebarWrapper />
            <DrawerStateSyncer>
              <Suspense fallback={<Loading />}>
                <Outlet />
              </Suspense>
            </DrawerStateSyncer>
          </AccessRightsProvider>
        </GraphqlProvider>
      </ContentErrorBoundary>
    </Secure>
  );
};

export default SecureRoutesWrapper;
