import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { CompaniesRoutes } from '../../../config/paths';

const CompaniesPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof CompaniesRoutes>> = {
  paths: {
    [CompaniesRoutes.COMPANIES]: {
      render: CompaniesPage,
    },
  },
};

export default config;
