import type { ReactNode, ReactElement } from 'react';

import '../../../services/yup/validators';
import { useAuth } from '@virtuslab/react-oauth2';

import SharedServicesProvider from '@virtuslab/nfs-shared/src/components/organisms/ServicesProvider';
import UsersnapProvider from '@virtuslab/nfs-shared/src/components/organisms/UsersnapProvider';
import makeUseProfileInfo from '@virtuslab/nfs-shared/src/services/hooks/useProfileInfo';

import config from '../../../config';

import TranslationsProvider from '../TranslationsProvider';

type ProfileShape = Readonly<{
  groups: string[];
}>;

export const useProfileInfo = makeUseProfileInfo<ProfileShape>();

type Props = Readonly<{
  children: ReactNode;
}>;

const ServicesProvider = ({ children }: Props): ReactElement => {
  const auth = useAuth();
  const authenticated = auth.isAuthenticated();
  const user = authenticated ? auth.getUserProfile() : null;

  return (
    <TranslationsProvider>
      <UsersnapProvider
        initParams={{
          custom: {
            version: config.release,
          },
          user: {
            email: user?.email,
          },
        }}
      >
        <SharedServicesProvider>
          {children}
        </SharedServicesProvider>
      </UsersnapProvider>
    </TranslationsProvider>
  );
};

export default ServicesProvider;
