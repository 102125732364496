import { StrictMode } from 'react';
import type { ReactElement } from 'react';

import { captureException, withSentryReactRouterV6Routing } from '@sentry/react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';

import AppVersionGuard from '@virtuslab/nfs-shared/src/components/organisms/AppVersionGuard';
import ContentErrorBoundary from '@virtuslab/nfs-shared/src/components/organisms/ContentErrorBoundary';
import FeatureFlagsProvider from '@virtuslab/nfs-shared/src/components/organisms/FeatureFlagsContext';
import SecurityProvider from '@virtuslab/nfs-shared/src/components/organisms/SecurityProvider';
import ThemeProvider from '@virtuslab/nfs-shared/src/components/organisms/ThemeProvider';

import config from './config';
import { featureFlags } from './config/featureFlags';
import { LogoutRoutes } from './config/paths';

import SecureRenderRoutes from './components/organisms/SecureRenderRoutes';
import ServicesProvider from './components/organisms/ServicesProvider';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

function App(): ReactElement {
  return (
    <FeatureFlagsProvider featureFlags={featureFlags}>
      <ContentErrorBoundary>
        <ThemeProvider>
          <SecurityProvider
            onError={captureException}
            config={config}
            redirectTo={LogoutRoutes.LOGOUT}
          >
            <StrictMode>
              <AppVersionGuard config={config}>
                <ServicesProvider>
                  <Router basename={config.basePath}>
                    <Helmet titleTemplate={`%s - ${config.titleName}`} defaultTitle={config.titleName} defer />
                    <SentryRoutes>
                      <Route path="*" element={<SecureRenderRoutes />} />
                    </SentryRoutes>
                  </Router>
                </ServicesProvider>
              </AppVersionGuard>
            </StrictMode>
          </SecurityProvider>
        </ThemeProvider>
      </ContentErrorBoundary>
    </FeatureFlagsProvider>
  );
}

export default App;
