import isNil from 'lodash/isNil';
import { v4 } from 'uuid';

import {
  B2BContractDraftDrawerTypename,
  AddCompanyDrawerTypename,
  AddPersonDrawerTypename,
  B2BPaymentDetailsDrawerTypename,
  EmploymentPaymentDetailsDrawerTypename,
  EmploymentBankAccountDrawerTypename,
  EmploymentContractDraftDrawerTypename,
  BonusDrawerTypename,
  AllBonusesDrawerTypename,
  B2BContractNoteDrawerTypename,
  EmploymentContractNoteDrawerTypename,
  RateCardDrawerTypename,
  CompanyBankAccountDrawerTypename,
  EditClientContractDrawerTypename,
  EditProfileDetailsDrawerTypename,
  ContractDetailsDrawerTypename,
  AddProjectDrawerTypename,
  ProjectMembersDrawerTypename,
  UserProjectsDrawerTypename,
  EditProjectDrawerTypename,
  CompanyDetailsDrawerTypename,
  CompanyTypeDrawerTypename,
  AddSpaceDrawerTypename,
  EditSpaceDrawerTypename,
  EmploymentSalaryDetailsDrawerTypename,
  B2BSalaryDetailsDrawerTypename,
  AddClientContractDrawerTypename,
  AssignBenefitDrawerTypename,
  AddInternalProjectDrawerTypename,
  ContractBankAccountDrawerTypename,
  BenefitNoteDrawerTypename,
  ProjectBillablesReportDrawerTypename,
  BenefitInvoicesReportDrawerTypename,
  ClientInvoicingReportDrawerTypename,
  CreateVariantDrawerTypename,
  CreateBenefitDrawerTypename,
  CreateSubBenefitDrawerTypename,
  BusinessTripsReportDrawerTypename,
  UopHolidaysReportDrawerTypename,
  EditBenefitDrawerTypename,
  EditBenefitVariantDrawerTypename,
  TimeReportsReportDrawerTypename,
  BonusPreviewDrawerTypename,
  EditBenefitVariantPriceDrawerTypename,
  BasicFinancialReportDrawerTypename,
  SetRateAndProgressionDrawerTypename,
  PeopleListReportDrawerTypename,
  InvoicesDrawerTypename,
  DirectReportingDrawerTypename,
  RecurringBonusDrawerTypename,
  RecurringBonusPaymentHistoryDrawerTypename,
  SubcontractDraftDrawerTypename,
  SubcontractNoteDrawerTypename,
  YearlyCostSummaryDrawerTypename,
  AnnualLeaveSummaryDrawerTypename,
  CostSummariesDrawerTypename,
  AnnualLeaveEntitlementDrawerTypename,
  SetFutureEntitlementDrawerTypename,
  AnnualLeavePreferencesDrawerTypename,
} from '@virtuslab/nfs-shared/src/schema/admin';
import type {
  AddCompanyDrawer,
  B2BContractDraftDrawer,
  AddPersonDrawer,
  AbstractDrawer,
  B2BPaymentDetailsDrawer,
  EmploymentPaymentDetailsDrawer,
  EmploymentBankAccountDrawer,
  EmploymentContractDraftDrawer,
  BonusDrawer,
  AllBonusesDrawer,
  B2BContractNoteDrawer,
  EmploymentContractNoteDrawer,
  CompanyBankAccountDrawer,
  RateCardDrawer,
  EditClientContractDrawer,
  EditProfileDetailsDrawer,
  ContractDetailsDrawer,
  AddProjectDrawer,
  ProjectMembersDrawer,
  UserProjectsDrawer,
  EditProjectDrawer,
  CompanyDetailsDrawer,
  CompanyTypeDrawer,
  AddSpaceDrawer,
  EditSpaceDrawer,
  EmploymentSalaryDetailsDrawer,
  B2BSalaryDetailsDrawer,
  AddClientContractDrawer,
  AssignBenefitDrawer,
  AddInternalProjectDrawer,
  ContractBankAccountDrawer,
  BenefitNoteDrawer,
  ProjectBillablesReportDrawer,
  BenefitInvoicesReportDrawer,
  ClientInvoicingReportDrawer,
  CreateVariantDrawer,
  CreateBenefitDrawer,
  CreateSubBenefitDrawer,
  BusinessTripsReportDrawer,
  UopHolidaysReportDrawer,
  EditBenefitDrawer,
  EditBenefitVariantDrawer,
  TimeReportsReportDrawer,
  BonusPreviewDrawer,
  BasicFinancialReportDrawer,
  EditBenefitVariantPriceDrawer,
  SetRateAndProgressionDrawer,
  PeopleListReportDrawer,
  InvoicesDrawer,
  DirectReportingDrawer,
  RecurringBonusDrawer,
  RecurringBonusPaymentHistoryDrawer,
  YearlyCostSummaryDrawer,
  AbstractDrawerUnionInterface,
  SubcontractDraftDrawer,
  SubcontractNoteDrawer,
  AnnualLeaveSummaryDrawer,
  CostSummariesDrawer,
  AnnualLeaveEntitlementDrawer,
  SetFutureEntitlementDrawer,
  AnnualLeavePreferencesDrawer,
} from '@virtuslab/nfs-shared/src/schema/admin';

export type Drawer = AbstractDrawer;
export type FlatDrawer = Omit<Drawer, 'child'>;
export type FlatDrawers = FlatDrawer[];

type NonEditableParams = '__typename' | 'child' | 'id' | 'visible';

export type AddCompanyDrawerModelParams = Omit<AddCompanyDrawer, NonEditableParams>;
export const AddCompanyDrawerModel = (
  initialData: AddCompanyDrawerModelParams = {},
): Required<AddCompanyDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  returnData: null,
  initialData: isNil(initialData) ? null : {
    __typename: 'AddCompanyDrawerInitialData',
    name: initialData.initialData?.name ?? null,
    taxNumber: initialData.initialData?.taxNumber ?? null,
    companyType: initialData.initialData?.companyType ?? null,
  },
  __typename: AddCompanyDrawerTypename,
});

type B2BContractDraftDrawerModelParams = Omit<B2BContractDraftDrawer, NonEditableParams>;
export const B2BContractDraftDrawerModel = (
  requiredParams: B2BContractDraftDrawerModelParams,
): Required<B2BContractDraftDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: B2BContractDraftDrawerTypename,
});

type SubcontractDraftDrawerModelParams = Omit<SubcontractDraftDrawer, NonEditableParams>;
export const SubcontractDraftDrawerModel = (
  requiredParams: SubcontractDraftDrawerModelParams,
): Required<SubcontractDraftDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: SubcontractDraftDrawerTypename,
});

export const AddPersonDrawerModel = (): Required<AddPersonDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  newUserId: null,
  __typename: AddPersonDrawerTypename,
});

type SetRateAndProgressionDrawerModelParams = Omit<SetRateAndProgressionDrawer, NonEditableParams>;
export const SetRateAndProgressionDrawerModel = (
  {
    effectiveDate, ...requiredParams
  }: SetRateAndProgressionDrawerModelParams,
): Required<SetRateAndProgressionDrawer> => ({
  ...requiredParams,
  effectiveDate: effectiveDate ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: SetRateAndProgressionDrawerTypename,
});

type B2BPaymentDetailsDrawerModelParams = Omit<B2BPaymentDetailsDrawer, NonEditableParams>;
export const B2BPaymentDetailsDrawerModel = (
  {
    contractId, effectiveDate, ...requiredParams
  }: B2BPaymentDetailsDrawerModelParams,
): Required<B2BPaymentDetailsDrawer> => ({
  ...requiredParams,
  contractId: contractId ?? null,
  effectiveDate: effectiveDate ?? null,
  id: v4(),
  child: null,
  visible: true,
  highlight: null,
  __typename: B2BPaymentDetailsDrawerTypename,
});

type EmploymentPaymentDetailsDrawerModelParams = Omit<EmploymentPaymentDetailsDrawer, NonEditableParams>;
export const EmploymentPaymentDetailsDrawerModel = (
  {
    contractId, effectiveDate, ...requiredParams
  }: EmploymentPaymentDetailsDrawerModelParams,
): Required<EmploymentPaymentDetailsDrawer> => ({
  ...requiredParams,
  contractId: contractId ?? null,
  effectiveDate: effectiveDate ?? null,
  id: v4(),
  child: null,
  visible: true,
  highlight: null,
  __typename: EmploymentPaymentDetailsDrawerTypename,
});

type EmploymentContractDrawerModelParams = Omit<EmploymentContractDraftDrawer, NonEditableParams>;
export const EmploymentContractDraftDrawerModel = (
  requiredParams: EmploymentContractDrawerModelParams,
): Required<EmploymentContractDraftDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: EmploymentContractDraftDrawerTypename,
});

type B2BContractNoteDrawerModelParams = Omit<B2BContractNoteDrawer, NonEditableParams>;
export const B2BContractNoteDrawerModel = (
  { contractId, ...requiredParams }: B2BContractNoteDrawerModelParams,
): Required<B2BContractNoteDrawer> => ({
  ...requiredParams,
  contractId: contractId ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: B2BContractNoteDrawerTypename,
});

type EmploymentContractNoteDrawerModelParams = Omit<EmploymentContractNoteDrawer, NonEditableParams>;
export const EmploymentContractNoteDrawerModel = (
  { contractId, ...requiredParams }: EmploymentContractNoteDrawerModelParams,
): Required<EmploymentContractNoteDrawer> => ({
  ...requiredParams,
  contractId: contractId ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: EmploymentContractNoteDrawerTypename,
});

type SubcontractNoteDrawerModelParams = Omit<SubcontractNoteDrawer, NonEditableParams>;
export const SubcontractNoteDrawerModel = (
  { contractId, ...requiredParams }: SubcontractNoteDrawerModelParams,
): Required<SubcontractNoteDrawer> => ({
  ...requiredParams,
  contractId: contractId ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: SubcontractNoteDrawerTypename,
});

type EmploymentBankAccountDrawerModelParams = Omit<EmploymentBankAccountDrawer, NonEditableParams>;
export const EmploymentBankAccountDrawerModel = (
  { contractId, ...requiredParams }: EmploymentBankAccountDrawerModelParams,
): Required<EmploymentBankAccountDrawer> => ({
  ...requiredParams,
  contractId: contractId ?? null,
  id: v4(),
  child: null,
  visible: true,
  highlight: null,
  __typename: EmploymentBankAccountDrawerTypename,
});

type CompanyBankAccountDrawerModelParams = Omit<CompanyBankAccountDrawer, NonEditableParams>;
export const CompanyBankAccountDrawerModel = (
  { initialValue, ...requiredParams }: CompanyBankAccountDrawerModelParams,
): Required<CompanyBankAccountDrawer> => ({
  ...requiredParams,
  initialValue: initialValue ?? null,
  id: v4(),
  child: null,
  visible: true,
  returnValue: null,
  __typename: CompanyBankAccountDrawerTypename,
});

type BonusDrawerModelParams = Omit<BonusDrawer, NonEditableParams>;
export const BonusDrawerModel = (
  { selectedBonusId, ...requiredParams }: BonusDrawerModelParams,
): Required<BonusDrawer> => ({
  ...requiredParams,
  selectedBonusId: selectedBonusId ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: BonusDrawerTypename,
});

type RecurringBonusDrawerModelParams = Omit<RecurringBonusDrawer, NonEditableParams>;
export const RecurringBonusDrawerModel = (
  { selectedBonusId, ...requiredParams }: RecurringBonusDrawerModelParams,
): Required<RecurringBonusDrawer> => ({
  ...requiredParams,
  selectedBonusId: selectedBonusId ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: RecurringBonusDrawerTypename,
});

type BonusPreviewDrawerModelParams = Omit<BonusPreviewDrawer, NonEditableParams>;
export const BonusPreviewDrawerModel = (
  { ...requiredParams }: BonusPreviewDrawerModelParams,
): Required<BonusPreviewDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: BonusPreviewDrawerTypename,
});

type RecurringBonusPaymentHistoryDrawerModelParams = Omit<RecurringBonusPaymentHistoryDrawer, NonEditableParams>;
export const RecurringBonusPaymentHistoryDrawerModel = (
  { ...requiredParams }: RecurringBonusPaymentHistoryDrawerModelParams,
): Required<RecurringBonusPaymentHistoryDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: RecurringBonusPaymentHistoryDrawerTypename,
});

type AllBonusesDrawerModelParams = Omit<AllBonusesDrawer, NonEditableParams>;
export const AllBonusesDrawerModel = (
  requiredParams: AllBonusesDrawerModelParams,
): Required<AllBonusesDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: AllBonusesDrawerTypename,
});

type AddClientContractDrawerModelParams = Omit<AddClientContractDrawer, NonEditableParams>;
export const AddClientContractDrawerModel = (
  { ...requiredParams }: AddClientContractDrawerModelParams,
): Required<AddClientContractDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: AddClientContractDrawerTypename,
});

type EditClientContractDrawerModelParams = Omit<EditClientContractDrawer, NonEditableParams>;
export const EditClientContractDrawerModel = (
  requiredParams: EditClientContractDrawerModelParams,
): Required<EditClientContractDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: EditClientContractDrawerTypename,
});

type RateCardDrawerModelParams = Omit<RateCardDrawer, NonEditableParams>;
export const RateCardDrawerModel = (
  { addNewPosition, effectiveDate, ...requiredParams }: RateCardDrawerModelParams,
): Required<RateCardDrawer> => ({
  ...requiredParams,
  addNewPosition: addNewPosition ?? null,
  effectiveDate: effectiveDate ?? null,
  highlight: null,
  id: v4(),
  child: null,
  visible: true,
  __typename: RateCardDrawerTypename,
});

type EditProfileDetailsDrawerModelParams = Omit<EditProfileDetailsDrawer, NonEditableParams>;
export const EditProfileDetailsDrawerModel = (
  { activeProfileTab, ...requiredParams }: EditProfileDetailsDrawerModelParams,
): Required<EditProfileDetailsDrawer> => ({
  ...requiredParams,
  activeProfileTab: activeProfileTab ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: EditProfileDetailsDrawerTypename,
});

type ContractDetailsDrawerModelParams = Omit<ContractDetailsDrawer, NonEditableParams>;
export const ContractDetailsDrawerModel = (
  requiredParams: ContractDetailsDrawerModelParams,
): Required<ContractDetailsDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: ContractDetailsDrawerTypename,
});

export const AddProjectDrawerModel = (): Required<AddProjectDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  projectId: null,
  __typename: AddProjectDrawerTypename,
});

type ProjectMembersDrawerModelParams = Omit<ProjectMembersDrawer, NonEditableParams>;
export const ProjectMembersDrawerModel = (
  { clientContractLink, ...requiredParams }: ProjectMembersDrawerModelParams,
): Required<ProjectMembersDrawer> => ({
  ...requiredParams,
  clientContractLink: clientContractLink ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: ProjectMembersDrawerTypename,
});

type UserProjectsDrawerModelParams = Omit<UserProjectsDrawer, NonEditableParams>;
export const UserProjectsDrawerModel = (
  requiredParams: UserProjectsDrawerModelParams,
): Required<UserProjectsDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: UserProjectsDrawerTypename,
});

type EditProjectDrawerModelParams = Omit<EditProjectDrawer, NonEditableParams>;
export const EditProjectDrawerModel = (
  requiredParams: EditProjectDrawerModelParams,
): Required<EditProjectDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: EditProjectDrawerTypename,
});

type CompanyDetailsDrawerModelParams = Omit<CompanyDetailsDrawer, NonEditableParams>;
export const CompanyDetailsDrawerModel = (
  { activeTab, ...requiredParams }: CompanyDetailsDrawerModelParams,
): Required<CompanyDetailsDrawer> => ({
  ...requiredParams,
  activeTab: activeTab ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: CompanyDetailsDrawerTypename,
});

type CompanyTypeDrawerModelParams = Omit<CompanyTypeDrawer, NonEditableParams>;
export const CompanyTypeDrawerModel = (
  requiredParams: CompanyTypeDrawerModelParams,
): Required<CompanyTypeDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: CompanyTypeDrawerTypename,
});

export const AddSpaceDrawerModel = (): Required<AddSpaceDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: AddSpaceDrawerTypename,
});

type EditSpaceDrawerModelParams = Omit<EditSpaceDrawer, NonEditableParams>;
export const EditSpaceDrawerModel = (
  requiredParams: EditSpaceDrawerModelParams,
): Required<EditSpaceDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: EditSpaceDrawerTypename,
});

type AssignBenefitDrawerModelParams = Omit<AssignBenefitDrawer, NonEditableParams>;
export const AssignBenefitDrawerModel = (
  { effectiveDate, ...requiredParams }: AssignBenefitDrawerModelParams,
): Required<AssignBenefitDrawer> => ({
  ...requiredParams,
  effectiveDate: effectiveDate ?? null,
  id: v4(),
  child: null,
  visible: true,
  highlight: null,
  __typename: AssignBenefitDrawerTypename,
});

type EmploymentSalaryDetailsDrawerParams = Omit<EmploymentSalaryDetailsDrawer, NonEditableParams>;
export const EmploymentSalaryDetailsDrawerModel = (
  requiredParams: EmploymentSalaryDetailsDrawerParams,
): Required<EmploymentSalaryDetailsDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: EmploymentSalaryDetailsDrawerTypename,
});

type B2BSalaryDetailsDrawerParams = Omit<B2BSalaryDetailsDrawer, NonEditableParams>;
export const B2BSalaryDetailsDrawerModel = (
  requiredParams: B2BSalaryDetailsDrawerParams,
): Required<B2BSalaryDetailsDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: B2BSalaryDetailsDrawerTypename,
});

export const AddInternalProjectDrawerModel = (): Required<AddInternalProjectDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  projectId: null,
  __typename: AddInternalProjectDrawerTypename,
});

type ContractBankAccountDrawerParams = Omit<ContractBankAccountDrawer, NonEditableParams>;
export const ContractBankAccountDrawerModel = (
  { contractId, ...requiredParams }: ContractBankAccountDrawerParams,
): Required<ContractBankAccountDrawer> => ({
  ...requiredParams,
  contractId: contractId ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: ContractBankAccountDrawerTypename,
});

type BenefitNoteDrawerParams = Omit<BenefitNoteDrawer, NonEditableParams>;
export const BenefitNoteDrawerModel = (
  { ...requiredParams }: BenefitNoteDrawerParams,
): Required<BenefitNoteDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: BenefitNoteDrawerTypename,
});

export const ProjectBillablesReportDrawerModel = (): Required<ProjectBillablesReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: ProjectBillablesReportDrawerTypename,
});

export const BenefitInvoicesReportDrawerModel = (): Required<BenefitInvoicesReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: BenefitInvoicesReportDrawerTypename,
});

export const ClientInvoicingReportDrawerModel = (): Required<ClientInvoicingReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: ClientInvoicingReportDrawerTypename,
});

type CreateVariantDrawerParams = Omit<CreateVariantDrawer, NonEditableParams>;
export const CreateVariantDrawerModel = (
  { ...requiredParams }: CreateVariantDrawerParams,
): Required<CreateVariantDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: CreateVariantDrawerTypename,
});

type CreateBenefitDrawerParams = Omit<CreateBenefitDrawer, NonEditableParams>;
export const CreateBenefitDrawerModel = (
  { newBenefitId, ...requiredParams }: CreateBenefitDrawerParams,
): Required<CreateBenefitDrawer> => ({
  ...requiredParams,
  newBenefitId: newBenefitId ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: CreateBenefitDrawerTypename,
});

type CreateSubBenefitDrawerParams = Omit<CreateSubBenefitDrawer, NonEditableParams>;
export const CreateSubBenefitDrawerModel = (
  { ...requiredParams }: CreateSubBenefitDrawerParams,
): Required<CreateSubBenefitDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: CreateSubBenefitDrawerTypename,
});

type EditBenefitDrawerParams = Omit<EditBenefitDrawer, NonEditableParams>;
export const EditBenefitDrawerModel = (
  { ...requiredParams }: EditBenefitDrawerParams,
): Required<EditBenefitDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: EditBenefitDrawerTypename,
});

type EditBenefitVariantDrawerParams = Omit<EditBenefitVariantDrawer, NonEditableParams>;
export const EditBenefitVariantDrawerModel = (
  { effectiveDate, ...requiredParams }: EditBenefitVariantDrawerParams,
): Required<EditBenefitVariantDrawer> => ({
  ...requiredParams,
  effectiveDate: effectiveDate ?? null,
  id: v4(),
  child: null,
  visible: true,
  highlight: null,
  __typename: EditBenefitVariantDrawerTypename,
});

type EditBenefitVariantPriceDrawerParams = Omit<EditBenefitVariantPriceDrawer, NonEditableParams>;
export const EditBenefitVariantPriceDrawerModel = (
  { ...requiredParams }: EditBenefitVariantPriceDrawerParams,
): Required<EditBenefitVariantPriceDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  highlight: null,
  __typename: EditBenefitVariantPriceDrawerTypename,
});

export const InvoicesDrawerModel = (
): Required<InvoicesDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: InvoicesDrawerTypename,
});

export const BusinessTripsReportDrawerModel = (): Required<BusinessTripsReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: BusinessTripsReportDrawerTypename,
});

export const UopHolidaysReportDrawerModel = (): Required<UopHolidaysReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: UopHolidaysReportDrawerTypename,
});

export const TimeReportsReportDrawerModel = (): Required<TimeReportsReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: TimeReportsReportDrawerTypename,
});

export const BasicFinancialReportDrawerModel = (): Required<BasicFinancialReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: BasicFinancialReportDrawerTypename,
});

export const PeopleListReportDrawerModel = (): Required<PeopleListReportDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: PeopleListReportDrawerTypename,
});

type DirectReportingDrawerParams = Omit<DirectReportingDrawer, NonEditableParams>;
export const DirectReportingDrawerModel = (
  { effectiveDate, highlight, ...requiredParams }: DirectReportingDrawerParams,
): DirectReportingDrawer => ({
  ...requiredParams,
  effectiveDate: effectiveDate ?? null,
  highlight: highlight ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: DirectReportingDrawerTypename,
});

type YearlyCostSummaryDrawerParams = Omit<YearlyCostSummaryDrawer, NonEditableParams>;
export const YearlyCostSummaryDrawerModel = (
  { ...requiredParams }: YearlyCostSummaryDrawerParams,
): Required<YearlyCostSummaryDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: YearlyCostSummaryDrawerTypename,
});

type AnnualLeaveSummaryDrawerParams = Omit<AnnualLeaveSummaryDrawer, NonEditableParams>;
export const AnnualLeaveSummaryDrawerModel = (
  { ...requiredParams }: AnnualLeaveSummaryDrawerParams,
): Required<AnnualLeaveSummaryDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: AnnualLeaveSummaryDrawerTypename,
});

type AnnualLeaveEntitlementDrawerParams = Omit<AnnualLeaveEntitlementDrawer, NonEditableParams>;
export const AnnualLeaveEntitlementDrawerModel = (
  { ...requiredParams }: AnnualLeaveEntitlementDrawerParams,
): Required<AnnualLeaveEntitlementDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: AnnualLeaveEntitlementDrawerTypename,
});

type SetFutureEtitlementDrawerParams = Omit<SetFutureEntitlementDrawer, NonEditableParams>;
export const SetFutureEntitlementDrawerModel = (
  { ...requiredParams }: SetFutureEtitlementDrawerParams,
): Required<SetFutureEntitlementDrawer> => ({
  ...requiredParams,
  initialDate: requiredParams.initialDate ?? null,
  id: v4(),
  child: null,
  visible: true,
  __typename: SetFutureEntitlementDrawerTypename,
});

type AnnualLeavePreferencesDrawerParams = Omit<AnnualLeavePreferencesDrawer, NonEditableParams>;
export const AnnualLeavePreferencesDrawerModel = (
  { ...requiredParams }: AnnualLeavePreferencesDrawerParams,
): Required<AnnualLeavePreferencesDrawer> => ({
  ...requiredParams,
  id: v4(),
  child: null,
  visible: true,
  __typename: AnnualLeavePreferencesDrawerTypename,
});

export const CostSummariesDrawerModel = (): Required<CostSummariesDrawer> => ({
  id: v4(),
  child: null,
  visible: true,
  __typename: CostSummariesDrawerTypename,
});

type AllDrawerModels = Readonly<{
  [key in NonNullable<AbstractDrawerUnionInterface['__typename']>]: unknown;
}>;

const makeEveryDrawerModelGetter = <T extends AllDrawerModels>(arg: T) => arg;

export const everyDrawerModel = makeEveryDrawerModelGetter({
  AddCompanyDrawer: AddCompanyDrawerModel,
  B2BContractDraftDrawer: B2BContractDraftDrawerModel,
  AddPersonDrawer: AddPersonDrawerModel,
  SetRateAndProgressionDrawer: SetRateAndProgressionDrawerModel,
  B2BPaymentDetailsDrawer: B2BPaymentDetailsDrawerModel,
  EmploymentPaymentDetailsDrawer: EmploymentPaymentDetailsDrawerModel,
  EmploymentContractDraftDrawer: EmploymentContractDraftDrawerModel,
  B2BContractNoteDrawer: B2BContractNoteDrawerModel,
  EmploymentContractNoteDrawer: EmploymentContractNoteDrawerModel,
  EmploymentBankAccountDrawer: EmploymentBankAccountDrawerModel,
  CompanyBankAccountDrawer: CompanyBankAccountDrawerModel,
  BonusDrawer: BonusDrawerModel,
  BonusPreviewDrawer: BonusPreviewDrawerModel,
  AllBonusesDrawer: AllBonusesDrawerModel,
  AddClientContractDrawer: AddClientContractDrawerModel,
  EditClientContractDrawer: EditClientContractDrawerModel,
  RateCardDrawer: RateCardDrawerModel,
  EditProfileDetailsDrawer: EditProfileDetailsDrawerModel,
  ContractDetailsDrawer: ContractDetailsDrawerModel,
  AddProjectDrawer: AddProjectDrawerModel,
  ProjectMembersDrawer: ProjectMembersDrawerModel,
  UserProjectsDrawer: UserProjectsDrawerModel,
  EditProjectDrawer: EditProjectDrawerModel,
  CompanyDetailsDrawer: CompanyDetailsDrawerModel,
  CompanyTypeDrawer: CompanyTypeDrawerModel,
  AddSpaceDrawer: AddSpaceDrawerModel,
  EditSpaceDrawer: EditSpaceDrawerModel,
  AssignBenefitDrawer: AssignBenefitDrawerModel,
  EmploymentSalaryDetailsDrawer: EmploymentSalaryDetailsDrawerModel,
  B2BSalaryDetailsDrawer: B2BSalaryDetailsDrawerModel,
  AddInternalProjectDrawer: AddInternalProjectDrawerModel,
  ContractBankAccountDrawer: ContractBankAccountDrawerModel,
  BenefitNoteDrawer: BenefitNoteDrawerModel,
  ProjectBillablesReportDrawer: ProjectBillablesReportDrawerModel,
  BenefitInvoicesReportDrawer: BenefitInvoicesReportDrawerModel,
  ClientInvoicingReportDrawer: ClientInvoicingReportDrawerModel,
  CreateVariantDrawer: CreateVariantDrawerModel,
  CreateBenefitDrawer: CreateBenefitDrawerModel,
  CreateSubBenefitDrawer: CreateSubBenefitDrawerModel,
  EditBenefitDrawer: EditBenefitDrawerModel,
  EditBenefitVariantDrawer: EditBenefitVariantDrawerModel,
  EditBenefitVariantPriceDrawer: EditBenefitVariantPriceDrawerModel,
  InvoicesDrawer: InvoicesDrawerModel,
  BusinessTripsReportDrawer: BusinessTripsReportDrawerModel,
  UopHolidaysReportDrawer: UopHolidaysReportDrawerModel,
  TimeReportsReportDrawer: TimeReportsReportDrawerModel,
  BasicFinancialReportDrawer: BasicFinancialReportDrawerModel,
  PeopleListReportDrawer: PeopleListReportDrawerModel,
  DirectReportingDrawer: DirectReportingDrawerModel,
  RecurringBonusDrawer: RecurringBonusDrawerModel,
  RecurringBonusPaymentHistoryDrawer: RecurringBonusPaymentHistoryDrawerModel,
  SubcontractDraftDrawer: SubcontractDraftDrawerModel,
  SubcontractNoteDrawer: SubcontractNoteDrawerModel,
  YearlyCostSummaryDrawer: YearlyCostSummaryDrawerModel,
  AnnualLeaveSummaryDrawer: AnnualLeaveSummaryDrawerModel,
  CostSummariesDrawer: CostSummariesDrawerModel,
  AnnualLeaveEntitlementDrawer: AnnualLeaveEntitlementDrawerModel,
  SetFutureEntitlementDrawer: SetFutureEntitlementDrawerModel,
  AnnualLeavePreferencesDrawer: AnnualLeavePreferencesDrawerModel,
});
