import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { ProjectsRoutes } from '../../../config/paths';

const ProjectsPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof ProjectsRoutes>> = {
  paths: {
    [ProjectsRoutes.PROJECTS]: {
      render: ProjectsPage,
    },
  },
};

export default config;
