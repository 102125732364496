
import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';
import { withRedirect } from '@virtuslab/nfs-shared/src/services/routes';

import { CompanyDetailsRoutes } from '../../../config/paths';

const ClientContracts = lazy(async () => import('../../organisms/ClientContracts'));
const CompanyDetails = lazy(async () => import('.'));
const Documents = lazy(async () => import('../../organisms/Documents'));

const config: RoutesConfig<WrapRoutes<typeof CompanyDetailsRoutes>> = {
  paths: {
    [CompanyDetailsRoutes.DETAILS]: {
      render: withRedirect(CompanyDetails, {
        from: CompanyDetailsRoutes.DETAILS,
        to: CompanyDetailsRoutes.CLIENT_CONTRACTS__NO_OPTIONALS,
      }),
    },
    [CompanyDetailsRoutes.CLIENT_CONTRACTS__NO_OPTIONALS]: {
      render: ClientContracts,
    },
    [CompanyDetailsRoutes.CLIENT_CONTRACTS]: {
      render: ClientContracts,
    },
    [CompanyDetailsRoutes.DOCUMENTS]: {
      render: Documents,
    },
  },
};

export default config;
