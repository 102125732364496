import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { SpaceDetailsRoutes } from '../../../config/paths';

const SpaceDetails = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof SpaceDetailsRoutes>> = {
  paths: {
    [SpaceDetailsRoutes.DETAILS]: {
      render: SpaceDetails,
    },
  },
};

export default config;
