import type { Location } from 'react-router';
import { useLocation, generatePath } from 'react-router';

import type { PathParams, EmptyParams } from '@virtuslab/nfs-shared/src/services/routes';

type GeneratePathWithFiltersParams<S extends string> = Readonly<{
  route: S;
  params?: PathParams<S, EmptyParams, number | string>;
}>;

type UseGeneratePathWithSearchReturnValue = Readonly<{
  generatePathWithSearch: <S extends string>({
    route,
    params,
  }: GeneratePathWithFiltersParams<S>) => Partial<Location>;
}>;

export const generatePathWithSearch = (
  search: string,
) => <S extends string>(
  { route, params }: GeneratePathWithFiltersParams<S>,
): Partial<Location> => {
  const pathname = generatePath(route as string, params);

  return {
    pathname,
    search,
  };
};

const useGeneratePathWithSearch = (): UseGeneratePathWithSearchReturnValue => {
  const location = useLocation();
  const { search } = location;

  return { generatePathWithSearch: generatePathWithSearch(search) };
};

export default useGeneratePathWithSearch;
