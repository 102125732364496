import {
  useEffect, useMemo, useRef, useState,
} from 'react';

import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import type { NavigateOptions, Path } from 'react-router';
import { useNavigate, useLocation } from 'react-router';
import type { Location } from 'react-router-dom';

import { isEmpty } from '../checks';
import type { Literal } from '../guards';
import { isArrayOf, isNotNil, isLiteral } from '../guards';
import type { Nullable } from '../object';
import { replaceObjectValues, compareObjectValues } from '../object';

import useSelfUpdatingRef from './useSelfUpdatingRef';

const isEmptyArrayOrLiteral = (
  arg: unknown,
): arg is Literal | unknown[] => (isLiteral(arg) && (isEmpty(arg) || isEmpty(Object.values(arg).filter(isNotNil))))
|| (isArray(arg) && isEmpty(arg));

const removeEmptyFields = (
  state: Literal,
): Nullable<Literal> => {
  const recurse = (current: Literal): Nullable<Literal> => {
    const entries = Object.entries(current)
      .map(([key, value]) => {
        const parsedValue = isLiteral(value) ? recurse(value) : value;

        if (isEmptyArrayOrLiteral(parsedValue) || isNil(parsedValue)) {
          return null;
        }

        if (key === 'search' && isEmpty(parsedValue)) {
          return null;
        }

        return [key, value] as const;
      }).filter(isNotNil);

    const newObj = Object.fromEntries(entries);
    return isEmptyArrayOrLiteral(newObj) ? null : newObj;
  };

  return recurse(state);
};

const mergeStates = (
  currentURLState: unknown, newState: unknown, key: string,
): Nullable<Literal> => {
  if (isLiteral(currentURLState)) {
    return {
      ...currentURLState,
      [key]: newState,
    };
  }

  return {
    [key]: newState,
  };
};

const removeOptionals = (state: Literal): Literal => Object.fromEntries(Object.entries(state).map(([key, value]) => {
  if (isNil(value)) {
    return null;
  }

  if (isLiteral(value)) {
    return [key, removeOptionals(value)] as const;
  }

  if (isArrayOf(value, isLiteral)) {
    return [key, value.map((fieldValue) => removeOptionals(fieldValue))] as const;
  }

  return [key, value] as const;
}).filter(isNotNil));

export const stateToSearch = (
  state: Nullable<Literal>,
): string => {
  if (isNil(state)) {
    return '';
  }

  const parsed = removeEmptyFields(removeOptionals(state));

  if (isNil(parsed)) {
    return '';
  }

  return encodeURIComponent(JSON.stringify(parsed));
};

const getState = (reconstructedSearchParams: unknown, key: string): unknown => {
  if (isLiteral(reconstructedSearchParams) && key in reconstructedSearchParams) {
    return reconstructedSearchParams[key];
  }

  return null;
};

export const parseSearch = (search: string): unknown => {
  const baseSearch = search
    .replace(/^\?/, '')
    .replace(/=$/, '');

  if (isEmpty(baseSearch)) {
    return null;
  }

  try {
    const parsedSearch: unknown = JSON.parse(decodeURIComponent(baseSearch));
    if (isLiteral(parsedSearch)) {
      return replaceObjectValues(parsedSearch, isString, (field) => {
        if (/^\d{4}-\d{2}-\d{2}T?/.test(field)) {
          return new Date(field);
        }

        return field;
      });
    }

    return parsedSearch;
  } catch {
    return null;
  }
};

export const linkWithSearch = (
  location: Location,
  state: Literal,
): Partial<Path> => {
  const { search, pathname } = location;
  const linkParams = {
    pathname,
    search: '',
    state: [],
  };

  const parsedSearch = parseSearch(search);
  const stringifiedSearch = stateToSearch({ ...isLiteral(parsedSearch) ? parsedSearch : {}, ...state });

  if (isEmpty(stringifiedSearch)) {
    return {
      pathname,
    };
  }

  linkParams.search = `?${stringifiedSearch}`;

  return linkParams;
};

type UseURLStateArg<T> = Readonly<{
  initialState?: Nullable<T>;
  assertShape: (value: unknown) => boolean;
  shouldClear?: (value: Nullable<T>) => boolean;
  key: string;
  navigationOptions?: NavigateOptions;
}>;

type UseURLStateReturnValue<T> = [
  value: Nullable<T>,
  setValue: (prev: Nullable<T>) => void,
];

const useURLState = <T>({
  assertShape,
  shouldClear,
  initialState,
  key,
  navigationOptions,
}: UseURLStateArg<T>): UseURLStateReturnValue<T> => {
  const { search: urlSearch, ...location } = useLocation();
  const navigate = useNavigate();

  const shouldClearRef = useSelfUpdatingRef(shouldClear ?? (() => false));
  const assertShapeRef = useSelfUpdatingRef(assertShape);
  const keyRef = useRef(key);

  const [state, setState] = useState(() => {
    const initialURLState = getState(parseSearch(urlSearch), keyRef.current);

    if (assertShape(initialURLState)) {
      return initialURLState;
    }

    return initialState;
  });

  useEffect(() => {
    const urlState = getState(parseSearch(urlSearch), keyRef.current);

    if (assertShapeRef.current(urlState)) {
      setState((prevState: unknown) => {
        if (isLiteral(prevState) && isLiteral(urlState)) {
          return isEmpty(compareObjectValues(urlState, prevState))
            ? prevState
            : urlState;
        }

        return urlState;
      });
    }
  }, [assertShapeRef, urlSearch]);

  const setValueRef = useSelfUpdatingRef((arg: Nullable<T>) => {
    navigate({
      ...location,
      search: stateToSearch(
        mergeStates(
          parseSearch(urlSearch), shouldClearRef.current(arg) ? null : arg, keyRef.current,
        ),
      ),
    }, navigationOptions);
  });

  const setValue = useMemo(() => (arg: Nullable<T>) => setValueRef.current(arg), [setValueRef]);

  return [
    state as Nullable<T>,
    setValue,
  ];
};

export default useURLState;
