import type { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import ProfileLink from '@virtuslab/nfs-shared/src/components/molecules/ProfileLink';
import { useFeatureFlagsContext } from '@virtuslab/nfs-shared/src/components/organisms/FeatureFlagsContext';
import NavSidebar from '@virtuslab/nfs-shared/src/components/organisms/NavSidebar';
import { removeInacessibleNavigationOptions } from '@virtuslab/nfs-shared/src/services/security';

import navigation, { profileNavigation } from '../../../config/navigation';

import { useCheckAccessRights } from '../AccessRightsProvider';
import { useProfileInfo } from '../ServicesProvider';

const NavSidebarWrapper = (): ReactElement => {
  const { t } = useTranslation();
  const { useACL } = useFeatureFlagsContext();
  const { routeAccessChecker } = useCheckAccessRights();
  const profile = useProfileInfo();

  const navOptions = navigation(t, routeAccessChecker);

  return (
    <NavSidebar
      navigation={useACL
        ? removeInacessibleNavigationOptions(profile?.groups ?? [], navOptions)
        : navOptions}
      profileLink={(
        <ProfileLink navigation={profileNavigation(t)} />
      )}
    />
  );
};

export default NavSidebarWrapper;
