import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { SalariesDetailsRoutes } from '../../../config/paths';

const SalariesDetailsPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof SalariesDetailsRoutes>> = {
  paths: {
    [SalariesDetailsRoutes.B2B]: {
      render: (params) => <SalariesDetailsPage {...params} type="b2b" />,
    },
    [SalariesDetailsRoutes.EMPLOYMENT]: {
      render: (params) => <SalariesDetailsPage {...params} type="employment" />,
    },
  },
};

export default config;
