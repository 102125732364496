import type { ReactElement, ReactNode } from 'react';
import {
  createContext, useContext, useEffect, useState,
} from 'react';

import { useAuth } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

import Loading from '@virtuslab/nfs-shared/src/components/molecules/Loading';
import { useAccessRightsProviderQuery } from '@virtuslab/nfs-shared/src/schema/admin';
import { hasAccess as unscopedHasAccess, type HooksWithPermissions } from '@virtuslab/nfs-shared/src/schema/admin/roleMap';
import { getError } from '@virtuslab/nfs-shared/src/services/errors';
import { isArrayOf, isObjectWithKeys } from '@virtuslab/nfs-shared/src/services/guards';
import type { Nullable } from '@virtuslab/nfs-shared/src/services/object';

import {
  BenefitsRoutes,
  CompaniesRoutes,
  CompanyDetailsRoutes,
  ContractsRoutes,
  ProjectsRoutes,
  SalariesDetailsRoutes,
  SalariesRoutes,
  SpacesRoutes,
  type RouteAccessChecker,
} from '../../../config/paths';

const parseJwt = (token: string): unknown => {
  const [, payload] = token.split('.');
  const base64Payload = payload.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64Payload).split('').map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''));
  return JSON.parse(jsonPayload);
};

type ContextValue = Readonly<{
  hasAccess: (hooks: HooksWithPermissions[]) => boolean;
  routeAccessChecker: RouteAccessChecker;
}>;

export const context = createContext<Nullable<ContextValue>>(null);

type Props = Readonly<{
  children: ReactNode;
}>;

const AccessRightsProvider = ({ children }: Props): ReactElement => {
  const { data, loading: queryLoading } = useAccessRightsProviderQuery();

  const [loading, setLoading] = useState(isNil(data) || queryLoading);
  const [contextValue, setContextValue] = useState<ContextValue>({
    hasAccess: () => false,
    routeAccessChecker: () => true,
  });

  const auth = useAuth();

  useEffect(() => {
    if (!isNil(data)) {
      setContextValue((prev) => ({
        ...prev,
        routeAccessChecker: (root) => {
          if (!isNil(getError(data.benefits))
            && root === BenefitsRoutes) {
            return false;
          }

          if (!isNil(getError(data.companies))
          && (root === CompaniesRoutes || root === CompanyDetailsRoutes)) {
            return false;
          }

          if (!isNil(getError(data.contractsEmploymentSalaryDetails))
        && (root === SalariesRoutes || root === SalariesDetailsRoutes)) {
            return false;
          }

          if (!isNil(getError(data.contracts))
            && (root === ContractsRoutes)) {
            return false;
          }

          if (!isNil(getError(data.projects))
            && (root === ProjectsRoutes)) {
            return false;
          }

          if (!isNil(getError(data.spaces))
            && (root === SpacesRoutes)) {
            return false;
          }

          return true;
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    auth.getAccessToken()
      .then((token) => (isNil(token) ? null : parseJwt(token)))
      .then((userProfile) => {
        if (!isNil(userProfile)) {
          if (isObjectWithKeys(userProfile, ['realm_access']) && isObjectWithKeys(userProfile.realm_access, ['roles'])) {
            const roles = isArrayOf(userProfile.realm_access.roles, isString) ? userProfile.realm_access.roles : [];
            setContextValue((prev) => ({
              ...prev,
              hasAccess: (hooks) => hooks.every((hook) => unscopedHasAccess(roles, hook)),
            }));
          }
        }

        setLoading(false);

        return null;
      });
  }, [auth]);

  return (
    <context.Provider value={contextValue}>
      {loading ? <Loading /> : children}
    </context.Provider>
  );
};

export const useCheckAccessRights = (): ContextValue => {
  const value = useContext(context);

  if (isNil(value)) {
    throw Error('Used useCheckAccessRights outside of context');
  }

  return value;
};

export default AccessRightsProvider;
