import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { ContractsRoutes } from '../../../config/paths';

const ContractsPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof ContractsRoutes>> = {
  paths: {
    [ContractsRoutes.CONTRACTS]: {
      render: ContractsPage,
    },
  },
};

export default config;
